import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, Margins, TDocumentDefinitions } from "pdfmake/interfaces";
import { BillSlaveMeasure, CommonAreasBillItems, DownloadBillSettingsGroupType, SlaveBill } from "../../interfaces/bill";
import { PDFBill, CompactBill, CompanyData, EntityInfo } from "../../interfaces/pdf-models";
import Slave, { ExternalSlave, SlaveDisplayCoefs } from "../../interfaces/slave";
import { CurrencyCode, CurrencyFormatFunction, useCurrency } from "../useCurrency.hook";
import { ElecUnit, OtherUnits, UnitFormatFunction, useUnit } from "../useUnit.hook";
import { getSlaveContract, getSlaveDescription, getSlaveTable } from "./general-bill.sections";
import { billFooter, billHeader } from "./sections";
import { tsToDate } from '../../utils/db.util';
import { formatDate, formatDateExportable } from '../../utils/date-formatter';
import * as XLSX from "xlsx";
import { useSlaveFormatting } from '../../views/slaves/helpers/useSlaveFormatting';

const fitToReportedDays = (amount: number, reportedDays: number) => {
  return (amount / 30) * reportedDays;
}

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
(pdfMake as any).fonts = {
  Mulish: {
    normal: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Mulish-Regular.ttf?alt=media&token=b7c583ed-9450-42e7-976d-a75588915bc7',
    bold: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Mulish-Bold.ttf?alt=media&token=bbcccc55-e053-436c-9ddd-a47983dd6806',
    italics: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Mulish-Regular.ttf?alt=media&token=b7c583ed-9450-42e7-976d-a75588915bc7',
    bolditalics: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Mulish-Bold.ttf?alt=media&token=bbcccc55-e053-436c-9ddd-a47983dd6806'
  },
  Oswald: {
    normal: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Oswald-Regular.ttf?alt=media&token=39379e03-5179-4d0e-b776-89c2252e1e2b',
    bold: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Oswald-Bold.ttf?alt=media&token=78a4e1ab-4821-45d7-a93f-b9cab777f669',
    italics: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Oswald-Regular.ttf?alt=media&token=39379e03-5179-4d0e-b776-89c2252e1e2b',
    bolditalics: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/Oswald-Regular.ttf?alt=media&token=39379e03-5179-4d0e-b776-89c2252e1e2b'
  },
}

const polluxLogoUrl = 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/logo-pollux.png?alt=media&token=b87277f6-f1fd-462d-9d8e-acd3f811a641';

const introducedDataTitle = (): Content => {
  return {
    pageBreak: 'before',
    layout: 'noBorders',
    margin: [49, 37, 49, 39],
    table: {
      headerRows: 0,
      body: [
        [
          {
            text: "DATOS INTRODUCIDOS",
            fontSize: 16,
            bold: true,
            alignment: "center",
          }
        ]
      ]
    }
  };
}

type CoefsRecord = Record<string, SlaveDisplayCoefs | undefined>;

const usePDFMake = () => {

  const { coef } = useSlaveFormatting();

  const getCoefsRecord = (slaves: Slave[]): CoefsRecord => {
    const record: CoefsRecord = {};
    
    slaves.forEach((slave) => {
      record[slave.id!] = slave.displayCoefs;
    });

    return record;
  }

  const { format: Bs } = useCurrency();
  const { format: BsPrec } = useCurrency(CurrencyCode.ves, { maxFractionDigits: 8 });
  const { format: kWh } = useUnit(ElecUnit.kwh);
  const { format: kVA } = useUnit(ElecUnit.kva);
  const { format: perc } = useUnit(OtherUnits.perc, { unitSignSpaced: false });
  const { format: percMinFrac2 } = useUnit(OtherUnits.perc, { unitSignSpaced: false, minFractionDigits: 2 });

  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ]

  const getEmissionDate = (date: Date): string => {
    return new Intl.DateTimeFormat("fr-FR").format(date);
  }

  const downloadGeneralBill = (
    companyData: CompanyData,
    billInformation: PDFBill,
    entityInformation: EntityInfo,
    externalSlaves: ExternalSlave[],
    slaveBills: SlaveBill[],
    downloadFormat?: String,
  ): void => {

    const getSlavesIntroducedData = (): Content[][] => {
      return externalSlaves
        .map((extSlave, i) => [
          getSlaveContract(extSlave, i),
          getSlaveDescription(extSlave),
          getSlaveTable(slaveBills[i], billInformation.tax, billInformation.kVaUnitCost, billInformation.kwhUnitCost, kWh, kVA, Bs, BsPrec),
        ]
        );
    }

    const arrayForXLSX: {
      Cuenta_Contrato: String,
      Medidor: String,
      Consumo_medido: String,
      Demanda_facturada: String,
      Ajuste_por_combustible: String,
      Valor_unitario_de_demanda_kVA: String,
      Valor_unitario_de_energía_KWh: String,
      Subtotal_sin_IVA: String,
      Total_por_servicios_de_electricidad: String
    }[] = externalSlaves
      .map((extSlave, i) => {
        const slaveBill: SlaveBill = slaveBills[i];

        return {
          Cuenta_Contrato: extSlave.contract,
          Medidor: extSlave.description,
          Consumo_medido: kWh(slaveBill.measuredConsumption),
          Demanda_facturada: kVA(slaveBill.invoicedDemand),
          Ajuste_por_combustible: kWh(slaveBill.fuelAdjustment),
          Valor_unitario_de_demanda_kVA: BsPrec(billInformation.kVaUnitCost),
          Valor_unitario_de_energía_KWh: BsPrec(billInformation.kwhUnitCost),
          Subtotal_sin_IVA: Bs(slaveBill.total),
          Total_por_servicios_de_electricidad: Bs(slaveBill.total * (1 + (billInformation.tax / 100)))
        }
      }
      )

    //PDF 1
    // console.log(billInformation)
    const beginDate = formatDate(tsToDate(billInformation.beginDate))
    const endDate = formatDate(tsToDate(billInformation.endDate))
    const generalBillPDFDef: TDocumentDefinitions = {
      // pageSize: 'A5',
      defaultStyle: {
        font: 'Mulish'
      },
      header: billHeader,
      footer: billFooter,
      pageMargins: [0, 110, 0, 50],
      content: [
        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['50%', '50%'],
            heights: [123],
            body: [
              [
                {
                  margin: [49, 37, 0, 0],
                  text: "Reporte general de torre",
                  fontSize: 26
                },
                {
                  margin: [0, 37, 49, 0],
                  alignment: "right",
                  columns: [
                    [
                      // second column consists of paragraphs
                      {
                        text: ["FECHA DE EMISION: ", { text: getEmissionDate(new Date()), bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["PERIODO: ", { text: beginDate + " a " + endDate, bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["DIAS REPORTADOS: ", { text: billInformation.totalDays, bold: false }], //TODO
                        fontSize: 11,
                        bold: true
                      }
                    ]
                  ]
                }
              ],
            ]
          }
        },
        {
          layout: 'noBorders', // optional
          margin: [49, 0, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['50%', '50%'],
            heights: [140],
            body: [
              [
                {
                  alignment: "left",
                  columns: [
                    [
                      // second column consists of paragraphs
                      {
                        text: "REPORTE PARA",
                        fontSize: 12,
                        lineHeight: 1.3,
                        bold: true
                      },
                      //Table
                      {
                        layout: 'noBorders', // optional
                        // margin: [ 49,0,0, 0 ],
                        table: {
                          // headers are automatically repeated if the table spans over multiple pages
                          // you can declare how many rows should be treated as headers
                          headerRows: 0,
                          widths: ['100%%'],
                          heights: [50],
                          body: [
                            [
                              {
                                // margin: [ 0, 32, 0, 0 ],
                                columns: [
                                  [
                                    {
                                      image: "entityLogo",
                                      fit: [60, 60]
                                    },
                                    // second column consists of paragraphs
                                    {
                                      text: entityInformation.edification,
                                      fontSize: 11,
                                      lineHeight: 1.2,
                                    },
                                    {
                                      text: [entityInformation.tower, ", ", entityInformation.floor],
                                      fontSize: 11,
                                      lineHeight: 1.2,
                                    },
                                    {
                                      text: [entityInformation.local],
                                      fontSize: 11,
                                      lineHeight: 1.2,
                                    }
                                  ]
                                ],
                              }
                            ]
                          ]
                        }
                      },
                    ]
                  ]
                },
                {
                  layout: 'noBorders', // optional
                  margin: [49, 0, 0, 0],
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 0,
                    widths: ['100%'],
                    heights: [83],
                    body: [
                      [
                        {
                          margin: [39, 14, 37, 0],
                          columns: [
                            [
                              {
                                text: "MONTO",
                                fontSize: 12,
                                alignment: "right",
                                color: "white",
                                bold: true,
                              },
                              {
                                text: Bs(billInformation.amount), //"Bs. 2745,13"
                                alignment: "center",
                                fontSize: 22,
                                color: "white",
                                bold: true,
                                fillColor: "#EE7202",
                              }
                            ]
                          ],
                          fillColor: "#EE7202",
                        },
                      ]
                    ]
                  }
                }
              ],
            ]
          }
        },
        {
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === 0 || i === 1) {
                return 0;
              }
              return 2;
            },
            vLineWidth: function (i: any, node: any) {
              if (i === 0 || i === 4) {
                return 0;
              }
              return 2;
            },
            hLineColor: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 'black';
              }
              return '#C4C4C4';
            },
            vLineColor: function (i: any) {
              return "#C4C4C4";
            },
            marginLeft: function (i: any) {
              return 39;
            },
            marginRight: function (i: any, node: any) {
              return 39;
            }
          }, // optional
          margin: [49, 0, 49, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['30%', '20%', '20%', '30%'],
            //heights: 27,
            body: [
              [
                {
                  text: "Descripcion",
                  alignment: "center",
                  bold: true,
                  fontSize: 12,
                  color: "white",
                  fillColor: "#000000"
                },
                {
                  text: "Cantidad",
                  fontSize: 12,
                  bold: true,
                  alignment: "center",
                  color: "white",
                  fillColor: "#000000"
                },
                {
                  text: "Precio Unitario",
                  fontSize: 12,
                  bold: true,
                  alignment: "center",
                  color: "white",
                  fillColor: "#000000"
                },
                {
                  text: "Subtotal",
                  fontSize: 12,
                  bold: true,
                  alignment: "center",
                  color: "white",
                  fillColor: "#000000"
                },
              ],
              [
                {
                  text: "Facturacion por consumo",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: kWh(billInformation.measuredConsumption),
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: BsPrec(billInformation.kwhUnitCost),
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  alignment: "right",
                  text: Bs(billInformation.totalCostMeasuredConsumption),
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "Facturación por Demanda",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: kVA(billInformation.invoicedDemand),
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: BsPrec(billInformation.kVaUnitCost),
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  alignment: "right",
                  text: Bs(billInformation.totalCostInvoicedDemand),
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "Ajuste por combustible",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: kWh(billInformation.fuelAdjustment),
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: BsPrec(billInformation.fuelUnitCost),
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  alignment: "right",
                  text: Bs(billInformation.totalCostFuelAdjustment),
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: "Subtotal",
                  alignment: "right",
                  colSpan: 2,
                  bold: true,
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {},
                {
                  alignment: "right",
                  text: Bs(billInformation.totalCostNoTax),
                  bold: true,
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: "IVA (" + billInformation.tax + "%)",
                  alignment: "right",
                  bold: true,
                  colSpan: 2,
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {},
                {
                  text: Bs(billInformation.taxCost),
                  bold: true,
                  alignment: "right",
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
            ]
          }
        },
        {
          layout: "noBorders",
          margin: [49, 0, 49, 39],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['70%', '30%'],
            //heights: [123],
            //heights: 27,
            body: [
              [
                {
                  text: "TOTAL",
                  alignment: "right",
                  fontSize: 14,
                  bold: true
                },
                {
                  text: Bs(billInformation.totalCostTax),
                  alignment: "right",
                  fontSize: 14,
                  bold: true
                },
              ],
            ]
          }
        },
        introducedDataTitle(),
        {
          layout: 'noBorders',
          margin: [49, 0, 49, 10],
          table: {
            headerRows: 0,
            body: [
              [
                {
                  text: "Valores generales",
                  fontSize: 14,
                  bold: true,
                }
              ]
            ]
          }
        },
        {
          margin: [49, 0, 49, 39],
          table: {
            headerRows: 0,
            widths: ["80%", "20%"],
            body: [
              [
                {
                  text: "Valor unitario de kWh",
                  bold: true,
                  fontSize: 12,
                },
                {
                  text: BsPrec(billInformation.kwhUnitCost),
                  fontSize: 12,
                  alignment: "right",
                },
              ],
              [
                {
                  text: "Valor unitario de kVA",
                  bold: true,
                  fontSize: 12,
                },
                {
                  text: BsPrec(billInformation.kVaUnitCost),
                  fontSize: 12,
                  alignment: "right",
                },
              ],
              [
                {
                  text: "Valor unitario de kWh por ajuste de combustible",
                  bold: true,
                  fontSize: 12,
                },
                {
                  text: BsPrec(billInformation.fuelUnitCost),
                  fontSize: 12,
                  alignment: "right",
                },
              ],
              [
                {
                  text: "Impuestos",
                  bold: true,
                  fontSize: 12,
                },
                {
                  text: perc(billInformation.tax),
                  fontSize: 12,
                  alignment: "right",
                },
              ]
            ]
          }
        },
        {
          pageBreak: 'before',
          layout: 'noBorders',
          margin: [49, 37, 49, 10],
          table: {
            headerRows: 0,
            body: [
              [
                {
                  text: "Valores de Medidores Externos",
                  fontSize: 14,
                  bold: true,
                }
              ]
            ]
          }
        },
        ...getSlavesIntroducedData(),
        // {
        //   layout: {
        //     hLineWidth: function (i: any, node: any) {
        //       if (i === 0 ||  i === 1) {
        //         return 0;
        //       }
        //       return 2;
        //     },
        //     vLineWidth: function (i: any, node: any) {
        //       if (i === 0 || i === 4) {
        //         return 0;
        //       }layout: 'noBorders',
        //       return 2;
        //     },
        //     hLineColor: function (i: any, node: any) {
        //       if (i === node.table.body.length) {
        //         return 'black';
        //       }
        //       return '#C4C4C4';
        //     },
        //     vLineColor: function (i: any) {
        //       return "#C4C4C4";
        //     },
        //     marginLeft: function (i: any) {
        //       return 39;
        //     },
        //     marginRight: function (i: any, node: any) {
        //       return 39;
        //     }
        //   }, // optional
        //   margin: [49, 0 , 49, 0],

        // }
      ] as any[],
      styles: {
        header: {
          background: 'blue'
        },
        title: {
          fontSize: 18,
          font: "Oswald",
          bold: true
        },
        subtitle: {
          color: "#797979",
          fontSize: 11
        }
      },
      images: {
        // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
        logo: polluxLogoUrl,
        entityLogo: entityInformation.logoUrl
      }
    };
    switch (downloadFormat) {
      case "pdf":
        pdfMake.createPdf(generalBillPDFDef).download(`${entityInformation.tower} - Factura Medidores Externos ${formatDateExportable(tsToDate(billInformation.beginDate))} al ${formatDateExportable(tsToDate(billInformation.endDate))}.pdf`);
        break;
      case "xlsx":
        downloadXLXS(arrayForXLSX, companyData, billInformation);
        break;
      default:
        break;
    }
  }

  const downloadCompactBill = (
    companyData: CompanyData,
    billInformation: CompactBill,
    entityInformation: EntityInfo,
    measuresDocs: any[],
    groupType: DownloadBillSettingsGroupType = "slave",
    commonAreasBillItems: CommonAreasBillItems,
    pdfBill: PDFBill,
    slaves: Slave[],
    downloadFormat?: String,
  ): void => {

    measuresDocs = measuresDocs.sort((a: any, b: any) => {
      return parseInt(a.data().slaveLocalId) - parseInt(b.data().slaveLocalId);
    });
    const coefsRecord = getCoefsRecord(slaves);

    let totalkWh = 0;
    let totalPerckWh = 0;
    let totalkVA = 0;
    let totalPerckVA = 0;
    let totalInternalSlaves = 0;

    let intSlavesTotalCostkWh = 0;
    let intSlavesTotalCostkVA = 0;

    const { kwhUnitCost, kVaUnitCost } = pdfBill;

    const slavesRowsItems: {
      rowkWh: number;
      rowPerckWh: number;
      rowkVA: number;
      rowPerckVA: number;
      rowSubtotal: number;
    }[] = [];

    for (const slaveDoc of measuresDocs) {
      const measure = slaveDoc.data() as BillSlaveMeasure;

      const rowkWh = coef(measure.totalEnergy, "kwh", coefsRecord[measure.slaveId]);
      totalkWh += rowkWh;
      const rowPerckWh = (rowkWh * 100) / pdfBill.measuredConsumption;
      totalPerckWh += rowPerckWh;
      const rowkVA = coef(measure.peakDemand, "kva", coefsRecord[measure.slaveId]);
      totalkVA += rowkVA;
      const rowPerckVA = (rowkVA * 100) / pdfBill.invoicedDemand;
      totalPerckVA += rowPerckVA;

      const rowCostkWh = (rowPerckWh / 100) * pdfBill.totalCostMeasuredConsumption * (1 + (pdfBill.tax / 100));
      const rowCostkVA = (rowPerckVA / 100) * pdfBill.totalCostInvoicedDemand * (1 + (pdfBill.tax / 100));

      const rowSubtotal = (rowCostkWh + rowCostkVA);
      totalInternalSlaves += rowSubtotal;

      slavesRowsItems.push({
        rowkWh,
        rowPerckWh,
        rowkVA,
        rowPerckVA,
        rowSubtotal,
      });
    }

    const _commonAreasPerckWh = 100 - totalPerckWh;
    const commonAreasPerckWh = _commonAreasPerckWh < 0 ? 0 : _commonAreasPerckWh;
    const _commonAreasPerckVA = 100 - totalPerckVA;
    const commonAreasPerckVA = _commonAreasPerckVA < 0 ? 0 : _commonAreasPerckVA;


    const _aux_commonAreasKwh = pdfBill.measuredConsumption - totalkWh;
    const commonAreasKwh = _aux_commonAreasKwh < 0 ? 0 : _aux_commonAreasKwh;
    const commonAreasKwhCost = (commonAreasPerckWh / 100) * pdfBill.totalCostMeasuredConsumption * (1 + (pdfBill.tax / 100));
    const _aux_commonAreasKva = pdfBill.invoicedDemand - totalkVA;
    const commonAreasKva = _aux_commonAreasKva < 0 ? 0 : _aux_commonAreasKva;
    const commonAreasKvaCost = (commonAreasPerckVA / 100) * pdfBill.totalCostInvoicedDemand * (1 + (pdfBill.tax / 100));

    const totalCostCommonAreas = commonAreasKwhCost + commonAreasKvaCost;

    const arrayForXLSX: {
      ID: String,
      kWh: String,
      Percent_kWh: String,
      kVA: String,
      Percent_kWA: String,
      Subtotal: String
    }[] = measuresDocs
      .map((_, i) => {
        const measure = measuresDocs[i].data()

        const {
          rowPerckVA,
          rowPerckWh,
          rowSubtotal,
          rowkVA,
          rowkWh,
        } = slavesRowsItems[i];

        return {
          ID: groupType === "slave" ? measure.slaveLocalId : measure.name,
          kWh: kWh(rowkWh),
          Percent_kWh: percMinFrac2(rowPerckWh),
          kVA: kVA(rowkVA),
          Percent_kWA: percMinFrac2(rowPerckVA),
          Subtotal: Bs(rowSubtotal)
        }
      }
      )
    arrayForXLSX.push({
      ID: "AACC",
      kWh: kWh(commonAreasKwh),
      Percent_kWh: percMinFrac2(commonAreasPerckWh),
      kVA: kVA(commonAreasKva),
      Percent_kWA: percMinFrac2(commonAreasPerckVA),
      Subtotal: Bs(totalCostCommonAreas)
    })

    arrayForXLSX.push({
      ID: "TOTAL",
      kWh: kWh(totalkWh + commonAreasKwh),
      Percent_kWh: percMinFrac2(totalPerckWh + commonAreasPerckWh),
      kVA: kVA(totalkVA + commonAreasKva),
      Percent_kWA: percMinFrac2(totalPerckVA + commonAreasPerckVA),
      Subtotal: Bs(totalInternalSlaves + totalCostCommonAreas)
    })

    const downloadCompactXLXS = (
      exportData: {
        ID: String,
        kWh: String,
        Percent_kWh: String,
        kVA: String,
        Percent_kWA: String,
        Subtotal: String
      }[],
      companyData: CompanyData,
      billInformation: PDFBill
    ) => {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `${entityInformation.tower} - Tabla Resumen Medidores Facturables ${formatDateExportable(tsToDate(billInformation.beginDate))} al ${formatDateExportable(tsToDate(billInformation.endDate))}.xlsx`
      );
    }

    //PDF 2
    const beginDate = formatDate(tsToDate(billInformation.beginDate))
    const endDate = formatDate(tsToDate(billInformation.endDate))
    const compactBillPDFDef = {
      // pageSize: 'A5',
      defaultStyle: {
        font: 'Mulish'
      },
      header: billHeader,
      footer: billFooter,
      content: [
        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['55%', '45%'],
            // heights: [80],
            body: [
              [
                {
                  margin: [49, 0, 0, 0],
                  text: "Resumen de Medidores Facturables",
                  fontSize: 24
                },
                {
                  margin: [0, 37, 49, 0],
                  alignment: "right",
                  columns: [
                    [
                      // second column consists of paragraphs
                      {
                        text: ["FECHA DE EMISION: ", { text: getEmissionDate(new Date()), bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["PERIODO: ", { text: beginDate + " a " + endDate, bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["DIAS REPORTADOS: ", { text: billInformation.totalDays, bold: false }],
                        fontSize: 11,
                        bold: true
                      }
                    ]
                  ]
                }
              ],
            ]
          }
        },
        {
          layout: 'noBorders', // optional
          margin: [49, 0, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['50%'],
            heights: [50],
            body: [
              [
                {
                  alignment: "left",
                  columns: [
                    [
                      {
                        text: entityInformation.edification,
                        fontSize: 11,
                        lineHeight: 1.2,
                        bold: true
                      },
                      {
                        text: ["Titular del contrato: ", { text: entityInformation.titularName, bold: false }],
                        fontSize: 11,
                        lineHeight: 1.2,
                        bold: true
                      },
                      {
                        text: ["CI/ RIF:", { text: entityInformation.titularId, bold: false }],
                        fontSize: 11,
                        lineHeight: 1.5,
                        bold: true
                      },
                    ]
                  ]
                },
              ],
            ]
          }
        },
        {
          layout: 'noBorders', // optional
          margin: [49, 0, 49, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['52%', '48%'],
            heights: [80],
            body: [
              [
                //TAble ORange
                {
                  layout: 'noBorders', // optional
                  margin: [0, 0, 12, 34],
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 0,
                    widths: ['100%'],
                    heights: [68],
                    body: [
                      [
                        {
                          margin: [9, 14, 0, 9],
                          columns: [
                            [
                              {
                                text: "FACTURA MEDIDORES EXTERNOS",
                                fontSize: 12,
                                alignment: "left",
                                color: "white",
                                bold: true,
                              },
                              {
                                text: Bs(billInformation.totalCostTax),
                                fontSize: 24,
                                color: "white",
                                bold: true,
                                fillColor: "#EE7202",
                              }
                            ]
                          ],
                          fillColor: "#EE7202",
                        },
                      ]
                    ]
                  }
                },
                //TAble ORange
                {
                  layout: 'noBorders', // optional
                  margin: [12, 0, 0, 34],
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 0,
                    widths: ['100%'],
                    heights: [68],
                    body: [
                      [
                        {
                          margin: [9, 14, 0, 9],
                          columns: [
                            [
                              {
                                text: "SUMA MEDIDORES FACTURABLES",
                                fontSize: 12,
                                alignment: "left",
                                color: "white",
                                bold: true,
                              },
                              {
                                text: Bs(billInformation.totalCostTax),
                                fontSize: 24,
                                color: "white",
                                bold: true,
                                fillColor: "#EE7202",
                              }
                            ]
                          ],
                          fillColor: "#EE7202",
                        },
                      ]
                    ]
                  }
                }
              ],
            ]
          }
        },
        {
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === 0 || i === 1) {
                return 0;
              }
              return 2;
            },
            vLineWidth: function (i: any, node: any) {
              if (i === 0 || i === 6) {
                return 0;
              }
              return 2;
            },
            hLineColor: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 'black';
              }
              return '#C4C4C4';
            },
            vLineColor: function (i: any) {
              return "#C4C4C4";
            },
            marginLeft: function (i: any) {
              return 39;
            },
            marginRight: function (i: any, node: any) {
              return 39;
            }
          }, // optional
          margin: [49, 0, 49, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            dontBreakRows: true,
            widths: ['9%', '23%', '11%', '23%', '11%', '23%'],
            //heights: 27,
            body: []
          }
        },
      ] as any[],
      styles: {
        header: {
          background: 'blue'
        },
        title: {
          fontSize: 18,
          font: "Oswald",
          bold: true
        },
        subtitle: {
          color: "#797979",
          fontSize: 11
        }
      },
      images: {
        // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
        logo: polluxLogoUrl,
        entityLogo: entityInformation.logoUrl
      },
      pageMargins: [0, 130, 0, 50] as Margins,
    };
    compactBillPDFDef.content[3].table.body.push([
      {
        text: groupType === "slave" ? "ID" : "Local",
        alignment: "center",
        bold: true,
        fontSize: 12,
        color: "white",
        fillColor: "#000000"
      },
      {
        text: "kWh",
        fontSize: 12,
        bold: true,
        alignment: "center",
        color: "white",
        fillColor: "#000000"
      },
      {
        text: "%kWh",
        fontSize: 12,
        bold: true,
        alignment: "center",
        color: "white",
        fillColor: "#000000"
      },
      {
        text: "kVA",
        fontSize: 12,
        bold: true,
        alignment: "center",
        color: "white",
        fillColor: "#000000"
      },
      {
        text: "%kVA",
        fontSize: 12,
        bold: true,
        alignment: "center",
        color: "white",
        fillColor: "#000000"
      },
      {
        text: "Subtotal",
        fontSize: 12,
        bold: true,
        alignment: "center",
        color: "white",
        fillColor: "#000000"
      },
    ])

    for (let i = 0; i < measuresDocs.length; i++) {
      const measure = measuresDocs[i].data() as BillSlaveMeasure;

      const {
        rowPerckVA,
        rowPerckWh,
        rowSubtotal,
        rowkVA,
        rowkWh,
      } = slavesRowsItems[i];

      compactBillPDFDef.content[3].table.body.push(
        [
          {
            text: groupType === "slave" ? measure.slaveLocalId : measure.name,
            fontSize: 11,
            lineHeight: 1.2
          },
          {
            text: kWh(rowkWh),
            fontSize: 11,
            lineHeight: 1.2,
            alignment: "right",
          },
          {
            text: percMinFrac2(rowPerckWh),
            fontSize: 11,
            lineHeight: 1.2,
            alignment: "right",
          },
          {
            text: kVA(rowkVA),
            fontSize: 11,
            lineHeight: 1.2,
            alignment: "right",
          },
          {
            text: percMinFrac2(rowPerckVA),
            fontSize: 11,
            lineHeight: 1.2,
            alignment: "right",
          },
          {
            alignment: "right",
            text: Bs(rowSubtotal),
            fontSize: 11,
            lineHeight: 1.2
          },
        ],
      )
    }

    compactBillPDFDef.content[3].table.body.push(
      [
        {
          text: "AACC",
          fontSize: 11,
          lineHeight: 1.2
        },
        {
          text: kWh(commonAreasKwh),
          fontSize: 11,
          lineHeight: 1.2,
          alignment: "right",
        },
        {
          text: percMinFrac2(commonAreasPerckWh),
          fontSize: 11,
          lineHeight: 1.2,
          alignment: "right",
        },
        {
          text: kVA(commonAreasKva),
          fontSize: 11,
          lineHeight: 1.2,
          alignment: "right",
        },
        {
          text: percMinFrac2(commonAreasPerckVA),
          fontSize: 11,
          lineHeight: 1.2,
          alignment: "right",
        },
        {
          alignment: "right",
          text: Bs(totalCostCommonAreas),
          fontSize: 11,
          lineHeight: 1.2
        },
      ],
    )

    compactBillPDFDef.content[3].table.body.push(
      [
        {
          text: "TOTAL",
          fontSize: 11,
          lineHeight: 1.2,
          fillColor: '#E9ECEF',
          bold: true,
        },
        {
          text: kWh(totalkWh + commonAreasKwh),
          fontSize: 11,
          lineHeight: 1.2,
          fillColor: '#E9ECEF',
          bold: true,
          alignment: "right",
        },
        {
          text: percMinFrac2(totalPerckWh + commonAreasPerckWh),
          fontSize: 11,
          lineHeight: 1.2,
          fillColor: '#E9ECEF',
          bold: true,
          alignment: "right",
        },
        {
          text: kVA(totalkVA + commonAreasKva),
          fontSize: 11,
          lineHeight: 1.2,
          fillColor: '#E9ECEF',
          bold: true,
          alignment: "right",
        },
        {
          text: percMinFrac2(totalPerckVA + commonAreasPerckVA),
          fontSize: 11,
          lineHeight: 1.2,
          fillColor: '#E9ECEF',
          bold: true,
          alignment: "right",
        },
        {
          alignment: "right",
          text: Bs(totalInternalSlaves + totalCostCommonAreas),
          fontSize: 11,
          lineHeight: 1.2,
          fillColor: '#E9ECEF',
          bold: true,
        },
      ],
    );

    // compactBillPDFDef.content.push({
    //   layout: 'noBorders',
    //   margin: [49, 39, 49, 10],
    //   table: {
    //     body: [
    //       [
    //         {
    //           text: "Áreas comunes",
    //           bold: true,
    //           fontSize: 14,
    //         }
    //       ]
    //     ]
    //   }
    // });

    // compactBillPDFDef.content.push({
    //   margin: [49, 0, 49, 10],
    //   table: {
    //     widths: ["80%", "20%"],
    //     headerRows: 0,
    //     body: [
    //       [
    //         {               
    //           text: "Energía",
    //           bold: true,
    //           fontSize: 12,
    //         },
    //         {               
    //           text: kWh(commonAreasKwh),
    //           fontSize: 12,
    //           alignment: "right",
    //         }, 
    //       ],
    //       [
    //         {               
    //           text: "Costo de energía",
    //           bold: true,
    //           fontSize: 12,
    //         },
    //         {               
    //           text: Bs(commonAreasKwhCost),
    //           fontSize: 12,
    //           alignment: "right",
    //         }, 
    //       ],
    //       [
    //         {               
    //           text: "Energía pico",
    //           bold: true,
    //           fontSize: 12,
    //         },
    //         {               
    //           text: kVA(commonAreasKva),
    //           fontSize: 12,
    //           alignment: "right",
    //         }, 
    //       ],
    //       [
    //         {               
    //           text: "Costo de pico de energía",
    //           bold: true,
    //           fontSize: 12,
    //         },
    //         {               
    //           text: Bs(commonAreasKvaCost),
    //           fontSize: 12,
    //           alignment: "right",
    //         }, 
    //       ],
    //     ]
    //   }
    // })

    // compactBillPDFDef.content.push({
    //   layout: "noBorders",
    //   margin: [49, 0, 49, 39],
    //   table: {
    //     // headers are automatically repeated if the table spans over multiple pages
    //     // you can declare how many rows should be treated as headers
    //     dontBreakRows: true,
    //     widths: ['70%', '30%'],
    //     //heights: [123],
    //     //heights: 27,
    //     body: [
    //       [
    //         {
    //           text: "TOTAL",
    //           alignment: "right",
    //           fontSize: 14,
    //           bold: true
    //         },
    //         {
    //           text: Bs(billInformation.totalCostTax),
    //           alignment: "right",
    //           fontSize: 14,
    //           bold: true
    //         },
    //       ],
    //     ]
    //   }
    // });

    switch (downloadFormat) {
      case "pdf":
        pdfMake.createPdf(compactBillPDFDef).download(`${entityInformation.tower} - Tabla Resumen Medidores Facturables ${formatDateExportable(tsToDate(billInformation.beginDate))} al ${formatDateExportable(tsToDate(billInformation.endDate))}.pdf`);
        break;
      case "xlsx":

        downloadCompactXLXS(arrayForXLSX, companyData, billInformation);
        break;
      default:
        break;
    }


  }

  const downloadXLXS = (
    exportData: {
      Cuenta_Contrato: String,
      Medidor: String,
      Consumo_medido: String,
      Demanda_facturada: String,
      Ajuste_por_combustible: String,
      Valor_unitario_de_demanda_kVA: String,
      Valor_unitario_de_energía_KWh: String,
      Subtotal_sin_IVA: String,
      Total_por_servicios_de_electricidad: String
    }[],
    companyData: CompanyData,
    billInformation: PDFBill
  ) => {
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `${companyData.name} - Tabla Resumen Medidores Facturables ${formatDateExportable(tsToDate(billInformation.beginDate))} al ${formatDateExportable(tsToDate(billInformation.endDate))}.xlsx`
    );
  }

  const downloadInternalSlaveBill = (
    billInformation: PDFBill,
    measure: BillSlaveMeasure,
    entityInformation: EntityInfo,
  ): void => {

    const beginDate = formatDate(tsToDate(billInformation.beginDate));
    const endDate = formatDate(tsToDate(billInformation.endDate));

    const slavekWh = measure.totalEnergy;
    const slavePerckWh = (slavekWh * 100) / billInformation.measuredConsumption;
    const slavekWhCost = (slavePerckWh / 100) * billInformation.totalCostMeasuredConsumption;
    const slavekVA = measure.peakDemand;
    const slavePerckVA = (slavekVA * 100) / billInformation.invoicedDemand;
    const slavekVACost = (slavePerckVA / 100) * billInformation.totalCostInvoicedDemand;
    const slaveSubtotal = slavekWhCost + slavekVACost + measure.fuelAdjustmentSubtotal;
    const slaveTax = slaveSubtotal * (billInformation.tax / 100);

    const internalSlaveBillDef: TDocumentDefinitions = {
      // pageSize: 'A5',
      defaultStyle: {
        font: 'Mulish'
      },
      content: [
        billHeader,
        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['50%', '50%'],
            heights: [123],
            body: [
              [
                {
                  margin: [49, 37, 0, 0],
                  text: "Reporte de Energía",
                  fontSize: 26
                },
                {
                  margin: [0, 37, 49, 0],
                  alignment: "right",
                  columns: [
                    [
                      // second column consists of paragraphs
                      {
                        text: ["FECHA DE EMISION: ", { text: getEmissionDate(new Date()), bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["PERIODO: ", { text: beginDate + " a " + endDate, bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["DIAS REPORTADOS: ", { text: billInformation.totalDays, bold: false }], //TODO
                        fontSize: 11,
                        bold: true
                      }
                    ]
                  ]
                }
              ],
            ]
          }
        }
      ] as any[],
      styles: {
        header: {
          background: 'blue'
        },
        title: {
          fontSize: 18,
          font: "Oswald",
          bold: true
        },
        subtitle: {
          color: "#797979",
          fontSize: 11
        }
      },
      images: {
        // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
        //logo: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/payments%2FwPqAJZiulE9ifmgVUn24?alt=media&token=49c456f7-e2c2-4b1d-a5ae-fce0632b8f20'
        logo: polluxLogoUrl,
        entityLogo: entityInformation.logoUrl
      },
      pageMargins: 0,
    };

    (internalSlaveBillDef.content as Array<Content>).push(
      {
        layout: 'noBorders', // optional
        margin: [49, 0, 0, 0],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 0,
          widths: ['42%', '58%'],
          heights: [140],
          body: [
            [
              {
                alignment: "left",
                columns: [
                  [
                    // second column consists of paragraphs
                    {
                      text: "REPORTE PARA",
                      fontSize: 12,
                      lineHeight: 1.3,
                      bold: true
                    },
                    //Table
                    {
                      layout: 'noBorders', // optional
                      // margin: [ 49,0,0, 0 ],
                      table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 0,
                        widths: ['100%'],
                        heights: [50],
                        body: [
                          [
                            {
                              // margin: [ 0, 32, 0, 0 ],
                              columns: [
                                [
                                  {
                                    image: "entityLogo",
                                    fit: [60, 60]
                                  },
                                  // second column consists of paragraphs
                                  {
                                    text: [entityInformation.edification],
                                    fontSize: 11,
                                    lineHeight: 1.2,
                                  },
                                  {
                                    text: [entityInformation.tower],
                                    fontSize: 11,
                                    lineHeight: 1.2,
                                  },
                                  {
                                    text: [`${measure.name}`],
                                    fontSize: 11,
                                    lineHeight: 1.2,
                                  }
                                ]
                              ],
                            }
                          ]
                        ]
                      }
                    },
                  ]
                ]
              },
              {
                layout: 'noBorders', // optional
                margin: [49, 0, 0, 0],
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 0,
                  widths: ['100%'],
                  heights: [83],
                  body: [
                    [
                      {
                        margin: [39, 14, 37, 0],
                        columns: [
                          [
                            {
                              text: "MONTO",
                              fontSize: 12,
                              alignment: "right",
                              color: "white",
                              bold: true,
                            },
                            {
                              text: Bs(slaveSubtotal + slaveTax), //"Bs. 2745,13"
                              alignment: "right",
                              fontSize: 22,
                              color: "white",
                              bold: true,
                              fillColor: "#EE7202",
                            }
                          ]
                        ],
                        fillColor: "#EE7202",
                      },
                    ]
                  ]
                }
              }
            ],
          ]
        }
      }
    );

    (internalSlaveBillDef.content as Array<Content>).push(
      {
        layout: {
          hLineWidth: function (i: any, node: any) {
            if (i === 0 || i === 1) {
              return 0;
            }
            return 2;
          },
          vLineWidth: function (i: any, node: any) {
            if (i === 0 || i === 4) {
              return 0;
            }
            return 2;
          },
          hLineColor: function (i: any, node: any) {
            if (i === node.table.body.length) {
              return 'black';
            }
            return '#C4C4C4';
          },
          vLineColor: function (i: any) {
            return "#C4C4C4";
          },
          // marginLeft: function (i: any) {
          //   return 39;
          // },
          // marginRight: function (i: any, node: any) {
          //   return 39;
          // }
        }, // optional
        margin: [49, 0, 49, 0],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          widths: ['30%', '20%', '20%', '30%'],
          dontBreakRows: true,
          //heights: 27,
          body: [
            [
              {
                text: "Descripción",
                alignment: "center",
                bold: true,
                fontSize: 12,
                color: "white",
                fillColor: "#000000"
              },
              {
                text: "Cantidad",
                fontSize: 12,
                bold: true,
                alignment: "center",
                color: "white",
                fillColor: "#000000"
              },
              {
                text: "Precio Unitario",
                fontSize: 12,
                bold: true,
                alignment: "center",
                color: "white",
                fillColor: "#000000"
              },
              {
                text: "Subtotal",
                fontSize: 12,
                bold: true,
                alignment: "center",
                color: "white",
                fillColor: "#000000"
              },
            ],
            [
              {
                text: "Facturación por Consumo",
                fontSize: 11,
                lineHeight: 1.2
              },
              {
                text: kWh(slavekWh),
                fontSize: 11,
                lineHeight: 1.2,
                alignment: "right",
              },
              {
                text: BsPrec(billInformation.kwhUnitCost),
                fontSize: 11,
                lineHeight: 1.2,
                alignment: "right",
              },
              {
                alignment: "right",
                text: Bs(slavekWhCost),
                fontSize: 11,
                lineHeight: 1.2
              },
            ],
            [
              {
                text: "Facturación por Demanda",
                fontSize: 11,
                lineHeight: 1.2
              },
              {
                text: kVA(slavekVA),
                fontSize: 11,
                lineHeight: 1.2,
                alignment: "right",
              },
              {
                text: BsPrec(billInformation.kVaUnitCost),
                fontSize: 11,
                lineHeight: 1.2,
                alignment: "right",
              },
              {
                alignment: "right",
                text: Bs(slavekVACost),
                fontSize: 11,
                lineHeight: 1.2
              },
            ],
            [
              {
                text: "Ajuste por Combustible",
                fontSize: 11,
                lineHeight: 1.2
              },
              {
                text: kWh(measure.fuelAdjustment),
                fontSize: 11,
                lineHeight: 1.2,
                alignment: "right",
              },
              {
                text: Bs(billInformation.fuelUnitCost),
                fontSize: 11,
                lineHeight: 1.2,
                alignment: "right",
              },
              {
                alignment: "right",
                text: Bs(measure.fuelAdjustmentSubtotal),
                fontSize: 11,
                lineHeight: 1.2
              },
            ],
            [
              {
                text: "",
                fontSize: 11,
                lineHeight: 1.2
              },
              {
                text: "Subtotal",
                alignment: "right",
                colSpan: 2,
                bold: true,
                fontSize: 11,
                lineHeight: 1.2
              },
              {},
              {
                alignment: "right",
                text: Bs(slaveSubtotal),
                bold: true,
                fontSize: 11,
                lineHeight: 1.2
              },
            ],
            [
              {
                text: "",
                fontSize: 11,
                lineHeight: 1.2
              },
              {
                text: "IVA (" + billInformation.tax + "%)",
                alignment: "right",
                bold: true,
                colSpan: 2,
                fontSize: 11,
                lineHeight: 1.2
              },
              {},
              {
                text: Bs(slaveTax),
                bold: true,
                alignment: "right",
                fontSize: 11,
                lineHeight: 1.2
              },
            ],
          ]
        },
      }
    );

    (internalSlaveBillDef.content as Array<Content>).push(
      {
        layout: "noBorders",
        margin: [49, 5, 49, 6],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 0,
          dontBreakRows: true,
          widths: ['70%', '30%'],
          //heights: [123],
          //heights: 27,
          body: [
            [
              {
                text: "TOTAL",
                alignment: "right",
                fontSize: 14,
                bold: true
              },
              {
                text: Bs(slaveSubtotal + slaveTax),
                alignment: "right",
                fontSize: 14,
                bold: true
              },
            ],
          ]
        }
      },
    );

    pdfMake.createPdf(internalSlaveBillDef).download(measure.slaveLocalId);
  }

  const downloadGroupTableBill = (
    companyData: CompanyData,
    billInformation: PDFBill,
    entityInformation: EntityInfo,
    slaveMeasures: any[],
    slaves: Slave[],
    groupType: DownloadBillSettingsGroupType = "slave",
  ): void => {
    
    const coefsRecord = getCoefsRecord(slaves);

    let totalkWh = 0;
    let totalPerckWh = 0;
    let totalkVA = 0;
    let totalPerckVA = 0;
    let totalInternalSlaves = 0;
    let totalFuelAdjustment = 0;

    const slavesRowsItems: {
      rowkWh: number;
      rowPerckWh: number;
      rowkVA: number;
      rowPerckVA: number;
      rowSubtotal: number;
    }[] = [];

    for (const slaveDoc of slaveMeasures) {
      const slave = slaveDoc.data() as BillSlaveMeasure;

      const rowkWh = coef(slave.totalEnergy, "kwh", coefsRecord[slave.slaveId!]);
      totalkWh += rowkWh;
      const rowPerckWh = (rowkWh * 100) / billInformation.measuredConsumption;
      totalPerckWh += rowPerckWh;
      const rowkVA = coef(slave.peakDemand, "kva", coefsRecord[slave.slaveId!]);
      totalkVA += rowkVA;
      const rowPerckVA = (rowkVA * 100) / billInformation.invoicedDemand;
      totalPerckVA += rowPerckVA;

      const rowCostkWh = (rowPerckWh / 100) * billInformation.totalCostMeasuredConsumption * (1 + (billInformation.tax / 100));
      const rowCostkVA = (rowPerckVA / 100) * billInformation.totalCostInvoicedDemand * (1 + (billInformation.tax / 100));

      const rowSubtotal = (rowCostkWh + rowCostkVA);
      totalInternalSlaves += rowSubtotal;
      totalFuelAdjustment += slave.fuelAdjustment;

      slavesRowsItems.push({
        rowkWh,
        rowPerckWh,
        rowkVA,
        rowPerckVA,
        rowSubtotal,
      });
    }

    const _commonAreasPerckWh = 100 - totalPerckWh;
    const commonAreasPerckWh = _commonAreasPerckWh < 0 ? 0 : _commonAreasPerckWh;
    const _commonAreasPerckVA = 100 - totalPerckVA;
    const commonAreasPerckVA = _commonAreasPerckVA < 0 ? 0 : _commonAreasPerckVA;


    const _aux_commonAreasKwh = billInformation.measuredConsumption - totalkWh;
    console.log("===> auc common areas kwh", _aux_commonAreasKwh)
    const commonAreasKwh = _aux_commonAreasKwh < 0 ? 0 : _aux_commonAreasKwh;
    const commonAreasKwhCost = (commonAreasPerckWh / 100) * billInformation.totalCostMeasuredConsumption * (1 + (billInformation.tax / 100));
    const _aux_commonAreasKva = billInformation.invoicedDemand - totalkVA;
    const commonAreasKva = _aux_commonAreasKva < 0 ? 0 : _aux_commonAreasKva;
    const commonAreasKvaCost = (commonAreasPerckVA / 100) * billInformation.totalCostInvoicedDemand * (1 + (billInformation.tax / 100));

    const totalCostCommonAreas = commonAreasKwhCost + commonAreasKvaCost;

    const _aux_commonFuelAdjustment = billInformation.fuelAdjustment - totalFuelAdjustment;
    const commonFuelAdjustment = _aux_commonFuelAdjustment < 0 ? 0 : _aux_commonFuelAdjustment;

    // PDF 3
    // console.log(slaveMeasures)
    const beginDate = formatDate(tsToDate(billInformation.beginDate))
    const endDate = formatDate(tsToDate(billInformation.endDate))
    const grouTableBillPDFDef: TDocumentDefinitions = {
      // pageSize: 'A5',
      defaultStyle: {
        font: 'Mulish'
      },
      header: billHeader,
      content: [] as any[],
      styles: {
        header: {
          background: 'blue'
        },
        title: {
          fontSize: 18,
          font: "Oswald",
          bold: true
        },
        subtitle: {
          color: "#797979",
          fontSize: 11
        }
      },
      images: {
        // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
        //logo: 'https://firebasestorage.googleapis.com/v0/b/ukko-pollux.appspot.com/o/payments%2FwPqAJZiulE9ifmgVUn24?alt=media&token=49c456f7-e2c2-4b1d-a5ae-fce0632b8f20'
        logo: polluxLogoUrl,
        entityLogo: entityInformation.logoUrl
      },
      pageMargins: [0, 110, 0, 50],
    };

    const buildBill: {
      name: String,
      slaveLocalId: String,
      slavekWh: number,
      slavePerckWh: number,
      slavekWhCost: number,
      slavekVA: number,
      slavePerckVA: number,
      slavekVACost: number,
      slaveSubtotal: number,
      slaveTax: number,
      fuelAdjustment: number,
      fuelAdjustmentSubtotal: number,
      slaveId: string;
    }[] = [];
    console.log("===> before bill push", commonAreasKwh)
    buildBill.push({
      name: "Áreas Comunes",
      slaveLocalId: "AACC",
      slavekWh: commonAreasKwh,
      slavePerckWh: commonAreasPerckWh,
      slavekWhCost: commonAreasKwhCost - (commonAreasKwhCost * (billInformation.tax / 100)),
      slavekVA: commonAreasKva,
      slavePerckVA: commonAreasPerckVA,
      slavekVACost: commonAreasKvaCost - (commonAreasKvaCost * (billInformation.tax / 100)),
      slaveSubtotal: totalCostCommonAreas - (totalCostCommonAreas * (billInformation.tax / 100)),
      slaveTax: totalCostCommonAreas * (billInformation.tax / 100),
      fuelAdjustment: commonFuelAdjustment,
      fuelAdjustmentSubtotal: commonFuelAdjustment * billInformation.fuelUnitCost,
      slaveId: "some-fake-id",
    })

    slaveMeasures.forEach((slaveMeasureDoc, index) => {
      const slaveMeasure: BillSlaveMeasure = slaveMeasureDoc.data();

      const slavekWh = coef(slaveMeasure.totalEnergy, "kwh", coefsRecord[slaveMeasure.slaveId]);
      const slavePerckWh = (slavekWh * 100) / billInformation.measuredConsumption;
      const slavekWhCost = (slavePerckWh / 100) * billInformation.totalCostMeasuredConsumption;
      const slavekVA = coef(slaveMeasure.peakDemand, "kva", coefsRecord[slaveMeasure.slaveId]);
      const slavePerckVA = (slavekVA * 100) / billInformation.invoicedDemand;
      const slavekVACost = (slavePerckVA / 100) * billInformation.totalCostInvoicedDemand;
      const slaveSubtotal = slavekWhCost + slavekVACost + slaveMeasure.fuelAdjustmentSubtotal;
      const slaveTax = slaveSubtotal * (billInformation.tax / 100);

      buildBill.push({
        name: slaveMeasure.name,
        slaveLocalId: slaveMeasure.slaveLocalId,
        slavekWh,
        slavePerckWh,
        slavekWhCost,
        slavekVA,
        slavePerckVA,
        slavekVACost,
        slaveSubtotal,
        slaveTax,
        fuelAdjustment: slaveMeasure.fuelAdjustment,
        fuelAdjustmentSubtotal: slaveMeasure.fuelAdjustmentSubtotal,
        slaveId: slaveMeasure.slaveId,
      })
      
    })

    buildBill.forEach((slaveMeasure) => {
      // (grouTableBillPDFDef.content as Array<Content>).push(
      //   {               
      //     margin: [ 49, 20, 0, 6 ],
      //     text: groupType === "slave" ? ("Medidor " + slaveMeasure.slaveLocalId + " - " + slaveMeasure.name) : "" + slaveMeasure.name,
      //     fontSize: 18,
      //     pageBreak: 'before'
      //   }
      // );

      const {
        name,
        slaveLocalId,
        slavekWh,
        slavePerckWh,
        slavekWhCost,
        slavekVA,
        slavePerckVA,
        slavekVACost,
        slaveSubtotal,
        slaveTax,
        fuelAdjustment,
        fuelAdjustmentSubtotal,
      } = slaveMeasure;

      (grouTableBillPDFDef.content as Array<Content>).push(
        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['50%', '50%'],
            heights: [123],
            body: [
              [
                {
                  margin: [49, 37, 0, 0],
                  text: "Reporte de Energía",
                  fontSize: 26
                },
                {
                  margin: [0, 37, 49, 0],
                  alignment: "right",
                  columns: [
                    [
                      // second column consists of paragraphs
                      {
                        text: ["FECHA DE EMISION: ", { text: getEmissionDate(new Date()), bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["PERIODO: ", { text: beginDate + " a " + endDate, bold: false }],
                        fontSize: 11,
                        bold: true
                      },
                      {
                        text: ["DIAS REPORTADOS: ", { text: billInformation.totalDays, bold: false }], //TODO
                        fontSize: 11,
                        bold: true
                      }
                    ]
                  ]
                }
              ],
            ]
          }
        }
      );

      (grouTableBillPDFDef.content as Array<Content>).push(
        {
          layout: 'noBorders', // optional
          margin: [49, 0, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['42%', '58%'],
            heights: [140],
            body: [
              [
                {
                  alignment: "left",
                  columns: [
                    [
                      // second column consists of paragraphs
                      {
                        text: "REPORTE PARA",
                        fontSize: 12,
                        lineHeight: 1.3,
                        bold: true
                      },
                      //Table
                      {
                        layout: 'noBorders', // optional
                        // margin: [ 49,0,0, 0 ],
                        table: {
                          // headers are automatically repeated if the table spans over multiple pages
                          // you can declare how many rows should be treated as headers
                          headerRows: 0,
                          widths: ['100%'],
                          heights: [50],
                          body: [
                            [
                              {
                                // margin: [ 0, 32, 0, 0 ],
                                columns: [
                                  [
                                    {
                                      image: "entityLogo",
                                      fit: [60, 60]
                                    },
                                    // second column consists of paragraphs
                                    {
                                      text: [entityInformation.edification],
                                      fontSize: 11,
                                      lineHeight: 1.2,
                                    },
                                    {
                                      text: [entityInformation.tower],
                                      fontSize: 11,
                                      lineHeight: 1.2,
                                    },
                                    {
                                      text: [`${name}`],
                                      fontSize: 11,
                                      lineHeight: 1.2,
                                    },
                                    {
                                      text: [`ID: ${slaveLocalId}`],
                                      fontSize: 11,
                                      lineHeight: 1.2,
                                    }
                                  ]
                                ],
                              }
                            ]
                          ]
                        }
                      },
                    ]
                  ]
                },
                {
                  layout: 'noBorders', // optional
                  margin: [49, 0, 0, 0],
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 0,
                    widths: ['100%'],
                    heights: [83],
                    body: [
                      [
                        {
                          margin: [39, 14, 37, 0],
                          columns: [
                            [
                              {
                                text: "MONTO",
                                fontSize: 12,
                                alignment: "right",
                                color: "white",
                                bold: true,
                              },
                              {
                                text: Bs(slaveSubtotal + slaveTax), //"Bs. 2745,13"
                                alignment: "right",
                                fontSize: 22,
                                color: "white",
                                bold: true,
                                fillColor: "#EE7202",
                              }
                            ]
                          ],
                          fillColor: "#EE7202",
                        },
                      ]
                    ]
                  }
                }
              ],
            ]
          }
        }
      );

      (grouTableBillPDFDef.content as Array<Content>).push(
        {
          layout: {
            hLineWidth: function (i: any, node: any) {
              if (i === 0 || i === 1) {
                return 0;
              }
              return 2;
            },
            vLineWidth: function (i: any, node: any) {
              if (i === 0 || i === 4) {
                return 0;
              }
              return 2;
            },
            hLineColor: function (i: any, node: any) {
              if (i === node.table.body.length) {
                return 'black';
              }
              return '#C4C4C4';
            },
            vLineColor: function (i: any) {
              return "#C4C4C4";
            },
            // marginLeft: function (i: any) {
            //   return 39;
            // },
            // marginRight: function (i: any, node: any) {
            //   return 39;
            // }
          }, // optional
          margin: [49, 0, 49, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            widths: ['30%', '20%', '20%', '30%'],
            dontBreakRows: true,
            //heights: 27,
            body: [
              [
                {
                  text: "Descripción",
                  alignment: "center",
                  bold: true,
                  fontSize: 12,
                  color: "white",
                  fillColor: "#000000"
                },
                {
                  text: "Cantidad",
                  fontSize: 12,
                  bold: true,
                  alignment: "center",
                  color: "white",
                  fillColor: "#000000"
                },
                {
                  text: "Precio Unitario",
                  fontSize: 12,
                  bold: true,
                  alignment: "center",
                  color: "white",
                  fillColor: "#000000"
                },
                {
                  text: "Subtotal",
                  fontSize: 12,
                  bold: true,
                  alignment: "center",
                  color: "white",
                  fillColor: "#000000"
                },
              ],
              [
                {
                  text: "Facturación por Consumo",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: kWh(slavekWh),
                  fontSize: 11,
                  lineHeight: 1.2,
                  alignment: "right",
                },
                {
                  text: BsPrec(billInformation.kwhUnitCost),
                  fontSize: 11,
                  lineHeight: 1.2,
                  alignment: "right",
                },
                {
                  alignment: "right",
                  text: Bs(slavekWhCost),
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "Facturación por Demanda",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: kVA(slavekVA),
                  fontSize: 11,
                  lineHeight: 1.2,
                  alignment: "right",
                },
                {
                  text: BsPrec(billInformation.kVaUnitCost),
                  fontSize: 11,
                  lineHeight: 1.2,
                  alignment: "right",
                },
                {
                  alignment: "right",
                  text: Bs(slavekVACost),
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "Ajuste por Combustible",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: kWh(fuelAdjustment),
                  fontSize: 11,
                  lineHeight: 1.2,
                  alignment: "right",
                },
                {
                  text: Bs(billInformation.fuelUnitCost),
                  fontSize: 11,
                  lineHeight: 1.2,
                  alignment: "right",
                },
                {
                  alignment: "right",
                  text: Bs(fuelAdjustmentSubtotal),
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: "Subtotal",
                  alignment: "right",
                  colSpan: 2,
                  bold: true,
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {},
                {
                  alignment: "right",
                  text: Bs(slaveSubtotal),
                  bold: true,
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
              [
                {
                  text: "",
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {
                  text: "IVA (" + billInformation.tax + "%)",
                  alignment: "right",
                  bold: true,
                  colSpan: 2,
                  fontSize: 11,
                  lineHeight: 1.2
                },
                {},
                {
                  text: Bs(slaveTax),
                  bold: true,
                  alignment: "right",
                  fontSize: 11,
                  lineHeight: 1.2
                },
              ],
            ]
          },
        }
      );

      (grouTableBillPDFDef.content as Array<Content>).push(
        {
          layout: "noBorders",
          margin: [49, 5, 49, 6],
          pageBreak: "after",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            dontBreakRows: true,
            widths: ['70%', '30%'],
            //heights: [123],
            //heights: 27,
            body: [
              [
                {
                  text: "TOTAL",
                  alignment: "right",
                  fontSize: 14,
                  bold: true
                },
                {
                  text: Bs(slaveSubtotal + slaveTax),
                  alignment: "right",
                  fontSize: 14,
                  bold: true
                },
              ],
            ]
          }
        },
      )
    })
    
    const pdfFile = pdfMake.createPdf(grouTableBillPDFDef)

    pdfFile.download(`${entityInformation.tower} - Detalle Medidores Facturables ${formatDateExportable(tsToDate(billInformation.beginDate))} al ${formatDateExportable(tsToDate(billInformation.endDate))}.pdf`);
  }



  return {
    downloadGeneralBill,
    downloadCompactBill,
    downloadGroupTableBill,
    downloadInternalSlaveBill
  };
};

export default usePDFMake;
