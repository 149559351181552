import { AmountSeparator } from "./useCurrency.hook";

export enum OtherUnits {
    perc = "perc"
}

export enum ElecUnit {
    kva = "kva",
    kwh = "kwh",
};

export enum UnitSignPosition {
    before = "before",
    after = "after",
}

export type Unit = ElecUnit | OtherUnits;

const unitSignDict
    : Record<Unit, string>
    = {
        [ElecUnit.kva]: "kVA",
        [ElecUnit.kwh]: "kWh",
        [OtherUnits.perc]: "%"
    };

export interface UnitFormatOptions {
    kSeparator?: AmountSeparator;
    accuracy?: number;
    unitSignPosition?: UnitSignPosition;
    unitSignSpaced?: boolean; 
    minFractionDigits?: number;
}

export type UnitFormatFunction = (amount: number, customUnit?: string) => string;

const defaultFormatOptions
    : Required<UnitFormatOptions>
    = {
        kSeparator: "dot",
        accuracy: 2,
        unitSignPosition: UnitSignPosition.after,
        unitSignSpaced: true,
        minFractionDigits: 0,
    };

export const useUnit = (unit?: ElecUnit | string, options: UnitFormatOptions = {}) => {

    options = { ...defaultFormatOptions, ...options };
    const {
        kSeparator,
        unitSignPosition,
        unitSignSpaced,
        minFractionDigits
    } = options;

    const config = new Intl.NumberFormat(
        kSeparator === "comma" ? "en-US" : "de-DE", 
        { maximumFractionDigits: options.accuracy, minimumIntegerDigits: 1, minimumFractionDigits: minFractionDigits }
    );

    const formatCustomUnit = (unit: string): string => {
        const indexOfUnit = Object.keys(ElecUnit).indexOf(unit);
        if (indexOfUnit !== -1) return unitSignDict[unit as ElecUnit]
        return unit;
    }

    const format: UnitFormatFunction = (amount: number, customUnit = "") => {
        
        try {
            const unitStr = unitSignDict[unit as ElecUnit] || unit || formatCustomUnit(customUnit);
            let result: string = config.format(amount);
            
            result = 
                unitSignPosition === UnitSignPosition.after ? 
                    result + (unitSignSpaced ? " " : "") + unitStr
                    :
                    unitStr + (unitSignSpaced ? " " : "") + result;
    
            return result;
        } catch (error) {
            console.error("Error formatting value:", amount, "\nDefault string was returned. Original error:\n", error);
            return "NaN";
        }
    }

    return { format };
}