import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { useState } from "react";
import styled from "styled-components";
import Loader from "../../../components/loader.component";
import { ElecUnit, useUnit } from "../../../hooks/useUnit.hook";
import useWindowDimensions from "../../../hooks/useWindowDimensions.hook";
import { Bill } from "../../../interfaces/bill";
import { ModalBaseParams } from "../../../interfaces/general";
import Slave, { ExternalSlave } from "../../../interfaces/slave";
import { formatDate } from "../../../utils/date-formatter";
import { tsToDate } from "../../../utils/db.util";
import BillDetail from "../detail.view";
import usePDFMake from '../../../hooks/PDFMake/usePDFMake.hook';
import { PDFBill, EntityInfo, CompanyData } from "../../../interfaces/pdf-models";
import { useFirestore } from "react-redux-firebase";

interface ExternalSlaveModalInput extends ModalBaseParams {
  extSlave: ExternalSlave | null;
  loading: boolean;
  bills: Bill[] | null;
}

const parseStatus = (status: any) => {
  if (status === "PAYED") {
    return "PAGADO";
  } else {
    return "PENDIENTE";
  }
};

const ExternalSlaveModal = (params: ExternalSlaveModalInput) => {

  const db = useFirestore();

  const { extSlave, visible, onCancel, loading, bills } = params;

  const { format: kWh } = useUnit(ElecUnit.kwh);
  
  const { width } = useWindowDimensions();

  const [ activeBillId, setActiveBillId ] = useState<string | null>(null);

  const { downloadGeneralBill } = usePDFMake();

  const handleBillRowClick = (id: string) => {
    if (activeBillId === id) return setActiveBillId(null);
    setActiveBillId(id);
  };

  const handleDownloadButtonClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>, bill: Bill) => {
    e.stopPropagation();

    const pdfBill: PDFBill = {
      amount: bill.total,
      beginDate: tsToDate(bill.beginDate),
      endDate: tsToDate(bill.endDate),
      fuelAdjustment: bill.fuelAdjustment,
      fuelUnitCost: bill.fuelUnitCost,
      invoicedDemand: bill.invoicedDemand,
      kVaUnitCost: bill.kVaUnitCost,
      kwhUnitCost: bill.kwhUnitCost,
      measuredConsumption: bill.measuredConsumption,
      number: "00567",
      tax: bill.tax,
      taxCost: bill.taxCost,
      totalCostFuelAdjustment: bill.totalCostFuelAdjustment,
      totalCostInvoicedDemand: bill.totalCostInvoicedDemand,
      totalCostMeasuredConsumption: bill.totalCostMeasuredConsumption,
      totalCostNoTax: bill.totalCostNoTax,
      totalCostTax: bill.totalCostTax,
      totalDays: bill.totalDays,
    };

    const entityInfo: EntityInfo =         {
      logoUrl: bill.companyData.avatarUrl,
      edification: bill.edification.split("@@")[1],
      tower: bill.tower.split("@@")[1],
      floor: "N/A",
      local: "N/A",
      slave: "N/A",
      titularName: bill.representantData.name,
      titularId: bill.representantData.id,
    };

    const companyData: CompanyData = {
      ...bill.companyData,
      logoUrl: bill.companyData.avatarUrl,
      phoneNumber: bill.companyData.phone,
    }


    try {
      downloadGeneralBill(
        companyData,
        pdfBill,
        entityInfo,
        bill.externalSlaves,
        bill.realFormValues.slavesBills,
        "pdf"
      );
    } catch (error) {
      message.error("Error al crear archivo de factura");
      console.log(error);
    }
    
  };

  if (!extSlave) return null;

  return (
    <Modal
      title={extSlave?.description || ""}
      visible={visible}
      width={width >= 700 ? "50vw" : "100vw"}
      centered
      onCancel={onCancel}
      footer={null}
    >
      <Paragraph>
        Facturas en las que está este medidor:
      </Paragraph>

      {
        loading &&
        <Loader />
      }

      {
        !loading && bills?.map((bill, i) => (
          <Row key={'bill-row-' + i}>
            <Col
              span={24}
              style={{ borderRadius: 8, backgroundColor: "#F7F6F9", marginBottom: 10 }}
            >
              <BillRow 
                onClick={() => handleBillRowClick(bill.id)} 
                style={{ alignItems: "center", background: "#A6CEE3" }}
              >
                <Col span={5} style={{ display: "flex", justifyContent: "center" }}>
                  {bill.contextsNames?.["company"] || "N/E"}
                </Col>
                <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                  {bill.beginDate
                    ? formatDate(tsToDate(bill.beginDate))
                    : "N/E"}
                  {" al "}
                  {bill.endDate
                    ? formatDate(tsToDate(bill.endDate))
                    : "N/E"}
                </Col>
                <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                  {bill.measuredConsumption
                    ? kWh(bill.measuredConsumption)
                    : "N/E"}
                </Col>
                <Col span={5} style={{ display: "flex", justifyContent: "center" }}>
                  {bill.status ? parseStatus(bill.status) : "N/E"}
                </Col>
                <Col
                  span={2}
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    zIndex: 1000,
                  }}
                >
                  {
                  bill.isComplete ?
                    <Button onClick={(e) => handleDownloadButtonClick(e, bill)} type="text">
                      <DownloadOutlined />
                    </Button>
                    :
                    <Loader />
                  }
                </Col>
              </BillRow>

              {
              activeBillId === bill.id &&
              <div style={{ padding: "10px 15px" }}>
                <BillDetail bill={bill} />
              </div>
              }
            </Col>
          </Row>
        ))
      }
    </Modal>
  );
}


const BillRow = styled(Row)`
  border-radius: 8px;
  background: #a6cee3;
  padding: 10px 15px;
  cursor: pointer;
`;

export default ExternalSlaveModal;
