import { Content } from "pdfmake/interfaces";

export const paymentInfo = (): Content[] => (
  [
    {
      layout: 'noBorders', // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 0,
        widths: ['100%'],
        // heights: [192],
        body: [
          [
            {
              margin: [49, 10, 0, 0],
              columns: [
                {
                  text: "Informacion de Pago",
                  style: {
                    color: "white"
                  }
                }
              ],
              fillColor: '#EE7202',
              // fillOpacity: 0.5
            }
          ]
        ]
      }
    },
    {
      layout: "noBorder",
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 0,
        widths: ['34%', '32%', '34%'], //, '25%', "25%"
        heights: [225],
        body: [
          [
            {
              layout: {
                hLineColor: function (i: any, node: any) {
                  return 'white';
                },
                vLineColor: function (i: any) {
                  return "white";
                },
              },
              margin: [12, 12, 12, 12],
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                body: [
                  {
                    layout: "noBorder",
                    table: {
                      // headers are automatically repeated if the table spans over multiple pages
                      // you can declare how many rows should be treated as headers
                      headerRows: 0,
                      widths: ['100%'], //, '25%', "25%"
                      body: [
                        [
                          {
                            text: "Inception"
                          }
                        ]
                      ]
                    },
                    fillColor: "#000000"
                  }
                ],
              },
            },
            {
              layout: "lightHorizontalLines",
              margin: [12, 12, 12, 12],
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                body: [
                  [{
                    text: "Gola"
                  }]
                ]
              },
              fillColor: '#000000'
            },
            {
              layout: "lightHorizontalLines",
              margin: [12, 12, 49, 12],
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                //margin: [ 12, 12, 12, 12 ],
                body: [
                  [{
                    text: "Gola",
                    style: {
                      color: "white"
                    }
                  }]
                ]
              },
              fillColor: '#000000'
            }
          ],
        ],
      },
      fillColor: '#EE7202'
    }
  ]
)

export const billHeader = {
  layout: 'noBorders', // optional
  table: {
    // headers are automatically repeated if the table spans over multiple pages
    // you can declare how many rows should be treated as headers
    headerRows: 0,
    widths: ['20%', '28%', "25%", '2%', '25%'],
    heights: [113],
    body: [
      [
        {
          margin: [49, 46, 0, 0],
          columns: [
            {
              image: "logo",
              fit: [80, 80]
            }
          ],
          fillColor: '#E9ECEF',
          // fillOpacity: 0.5
        },
        {
          margin: [0, 32, 0, 0],
          columns: [
            [
              // second column consists of paragraphs
              {
                text: "",
                style: ["title"]
              },
              {
                text: "",
                fontSize: 12
              }
            ]
          ],
          fillColor: '#E9ECEF',
          // fillOpacity: 0.5
        },
        {
          margin: [0, 60, 0, 0],
          text: "",
          alignment: "center",
          style: ["subtitle"],
          fillColor: '#E9ECEF',
          // fillOpacity: 0.5
        },
        {
          // If no width/height/fit is used, then dimensions from the svg element is used.
          height: 80,
          svg: '<svg height="210" width="500"> <line x1="10" y1="28" x2="10" y2="100" style="stroke:#C4C4C4;stroke-width:2" /></svg>',
          fillColor: "#E9ECEF"
        },
        {
          margin: [0, 41, 0, 0],
          columns: [
            [
              // second column consists of paragraphs
              {
                text: "info@pollux.energy",
                style: ["subtitle"]
              },
              {
                text: "@pollux.energy",
                style: ["subtitle"]
              }
            ]
          ],
          fillColor: '#E9ECEF',
          // fillOpacity: 0.5
        }
      ],
    ]
  }
}

export const billFooter = (currentPage: Number, pageCount: Number) => (
  {
    layout: 'noBorders', // optional
    table: {
      // headers are automatically repeated if the table spans over multiple pages
      // you can declare how many rows should be treated as headers
      headerRows: 0,
      widths: ['100%'],
      heights: [113],
      alignment: 'center',
      body: [
        [
          {
            margin: [0, 15, 0, 10],
            alignment: 'center',
            width: ['100%'],
            columns: [
              [
                // second column consists of paragraphs
                {
                  text: `${currentPage.toString()} de ${pageCount}`,
                  style: ["subtitle"]
                }
              ]
            ],
            fillColor: '#E9ECEF',
            // fillOpacity: 0.5
          }
        ],
      ]
    }
  }
)