import { Button, Card } from "antd";
import styled from "styled-components";
import Paragraph from "antd/lib/typography/Paragraph";
import { ExternalSlave } from "../../../interfaces/slave";

interface ExternalSlaveCardInput {
    data: ExternalSlave;
    onSee: (extSlave: ExternalSlave) => void;

}

const ExternalSlaveCard = ({ data, onSee }: ExternalSlaveCardInput) => {

    const CardButtonExtra = <Button style={{ zIndex: 9 }} onClick={() => onSee(data)}>Ver</Button>;

    return (
        <SlaveCardElement>
            <Card title={data.description} extra={CardButtonExtra}>
                <Paragraph>
                    Contrato: { data.contract }
                </Paragraph>
                <Paragraph>
                    Modelo: { data.model }
                </Paragraph>
            </Card>
        </SlaveCardElement>
    );  
};

export default ExternalSlaveCard;

const SlaveCardElement = styled.div`
  cursor: pointer;

  .ant-card-bordered {
    position: relative;
    .ant-card-head {
      background-color: #878786;
      color: white
    }

    .ant-card-body {
      background-color: #f7f6f9;
    }
    
    &:hover {
      background-color: #ededed3b;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        border: 2px solid #E97877;
        border-radius: 10px;
      }
    }
  }
`;