import { createStore, applyMiddleware, Middleware, Store } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import RootReducer from "../redux/root.reducer";

const middlewares: Middleware[] = [thunk];

if (process.env.NODE_ENV === "development") {
  // middlewares.push(logger);
}

export const store: Store<any> = createStore(RootReducer, applyMiddleware(...middlewares));
