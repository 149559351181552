import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import serviceworkerReducer from "./service-worker.reducer";

const rootReducer: any = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  serviceWorker: serviceworkerReducer,
});

export default rootReducer;
