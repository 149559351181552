import React, { createElement, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth.hook';
// import RouteType from "../interfaces/route";
import MainLayout from '../components/main-layout.component';
import useCredentials from '../hooks/useCredentials.hook';
import { UserCredentials } from '../interfaces/credentials';
import { contextAllowed, getContextLevel } from '../utils/context-helper';

const ProtectedRoute = ({ privateRoutes, adminRoutes, superAdminRoutes, ...rest }: { privateRoutes: Array<any>, adminRoutes: Array<any>, superAdminRoutes: Array<any> }) => {
    const { isAuthenticaded } = useAuth();

    const credentials = useCredentials();

    const [ isSuperAdmin, setIsSuperadmin ] = useState<boolean | null>(null);
    const [ isAdmin, setIsAdmin ] = useState<boolean | null>(null);
    const [ userContextLevel, setUserContextLevel ] = useState<number>(Infinity);

    useEffect(() => {
        if (credentials.loaded) {
            const userCredentials = credentials.currentCredentials as UserCredentials;
      
            setIsSuperadmin(userCredentials.context === "1o");
            setIsAdmin(userCredentials.context.split("o").length === 3);
            setUserContextLevel(getContextLevel(userCredentials.context));
        }
    }, [credentials]);

    const privateRoutesElements = privateRoutes.map((route) => {
        const { maxContextLevel, show } = route;
        const routeEl = <Route key={route.name} {...route} />;
        if (!credentials.loaded) return null;
        if (route.showFn) {
            if (!route.showFn(credentials.currentCredentials)) return null;
        }
        if (maxContextLevel === undefined) return routeEl;
        if (maxContextLevel === null) return routeEl;
        if (contextAllowed(userContextLevel, maxContextLevel)) return routeEl;
        return null;
    });

    const adminRoutesElements 
        = (isAdmin || isSuperAdmin) ? adminRoutes.map((route) => <Route key={route.name} {...route} />) : [];
    const superAdminRoutesElements 
        = isSuperAdmin ? 
            superAdminRoutes.map((route) => <Route key={route.name} {...route} />)
            : 
            [];

    if (isAuthenticaded()) {
        return (
            <MainLayout {...rest}>
                <Switch>
                    <Redirect exact from="/" to="/slaves" />
                    { privateRoutesElements }
                    { adminRoutesElements }
                    { superAdminRoutesElements }
                </Switch>
            </MainLayout>
        );
    }
    return <Redirect to={{ pathname: '/log-in' }} />;
};

export default ProtectedRoute;
