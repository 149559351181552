import { actionTypes } from "redux-firestore";
import { useSelector, useDispatch } from "react-redux";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { functions } from "../config/firebase.config";

import Credentials from "../interfaces/credentials";

const useAuth = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.firebase.auth);

  const login = async (credentials: Credentials): Promise<void> => {
    try {
      await firebase.login(credentials);
    } catch (error: any) {
      switch (error.code) {
        case "auth/network-request-failed":
          throw new Error(error.code);
        case "auth/user-not-found":
          throw new Error(error.code);
        case "auth/wrong-password":
          throw new Error(error.code);
        case "auth/invalid-email":
          throw new Error(error.code);
        case "auth/too-many-requests":
          throw new Error(error.code);
        default:
          throw new Error(error.code);
      }
    }
  };

  const logout = async (): Promise<void> => {
    await firebase.logout();
    dispatch({ type: actionTypes.CLEAR_DATA });
    return;
  };

  const sendPasswordReset = async (email: string): Promise<void> => {
    firebase.auth().useDeviceLanguage();
    try {
      const resetPasswordByEmailFn = functions.httpsCallable("resetPasswordByEmail");
      await resetPasswordByEmailFn({ email });
      return Promise.resolve();
    } catch (error: any) {
      return Promise.reject(error)
    }
  };

  const isAuthenticaded = (): boolean => isLoaded(auth) && !isEmpty(auth);

  const isAuthLoaded = (): boolean => isLoaded(auth);

  const getUID = (): string | undefined => firebase.auth().currentUser?.uid;

  return {
    login,
    logout,
    sendPasswordReset,
    isAuthenticaded,
    isAuthLoaded,
    getUID,
  };
};

export default useAuth;
