import {
  useFirestore,
  isLoaded,
  isEmpty,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

const useCredentials = () => {
  const db = useFirestore();
  const auth = useSelector((state: any) => state.firebase.auth);
  const credentialsOrdered = useSelector(
    (state: any) => state.firestore.ordered.permits
  );
  const credentialsData = useSelector(
    (state: any) => state.firestore.data.permits
  );
  const currentUser = useSelector(
    (state: any) => state.firestore.data.currentUser
  );
  let uid = "";

  let queries = [
    {
      collection: "users",
      doc: "",
      storeAs: "currentUser",
    },
    {
      collection: "users",
      doc: "",
      subcollections: [
        {
          collection: "permits",
          where: ["deleted" , "==", false],
          storeAs: "permits",
        }
      ],
      storeAs: "permits",
    },
  ] as any;

  if (isLoaded(auth) && !isEmpty(auth)) {
    uid = auth.uid;
    queries[0].doc = uid;
    queries[1].doc = uid;
  }
  // console.log("AUTH2: ", auth)
  //console.log(queries)
  useFirestoreConnect(queries);

  const changeCredentials = (credentialsId: string) => {
    return db
      .collection("users")
      .doc(uid)
      .update({ currentPermitId: credentialsId });
  };

  const credentials = {
    loaded:
      isLoaded(credentialsData) &&
      isLoaded(credentialsOrdered) &&
      isLoaded(currentUser),
    currentUser: currentUser,
    currentCredentials:
      isLoaded(credentialsData) &&
      isLoaded(currentUser) &&
      !isEmpty(currentUser)
        ? {
            id: currentUser.currentPermitId,
            ...credentialsData[currentUser.currentPermitId],
          }
        : undefined,
    credentialsOrdered: credentialsOrdered,
    credentialsData: credentialsData,
    changeCredentials: changeCredentials,
  };

  return credentials;
};

export default useCredentials;
