import { WhereOptions } from "react-redux-firebase";

type EntityName = "admin" | "company" | "physicalRegion" | "edification" | "tower" | "floor" | "local" | "slave";

const entitiesLevelsRecord 
  : { [key in EntityName]: number }
  = {
    "admin": 0,
    "company": 1,
    "physicalRegion": 2,
    "edification": 3,
    "tower": 4,
    "floor": 5,
    "local": 6,
    "slave": 7,
  };

const splitContextToLength = (context: string, length: number) => {
  const splited = context.split("o");
  const checked = splited.filter((elm) => !!elm);
  const chopped = checked.slice(0, length);
  return chopped.join("o") + "o";
};

const companyFromContext = (context: string) =>
  splitContextToLength(context, 2);

const regionFromContext = (context: string) => splitContextToLength(context, 3);

const edificationFromContext = (context: string) =>
  splitContextToLength(context, 4);

const towerFromContext = (context: string) => splitContextToLength(context, 5);

const floorFromContext = (context: string) => splitContextToLength(context, 6);

const localFromContext = (context: string) => splitContextToLength(context, 7);

const slaveFromContext = (context: string) => splitContextToLength(context, 8);

const getAllowedEntity = (type: EntityName, context: string): string | null => {
  const division = context.split("o");
  division.pop();

  const levelIdx = entitiesLevelsRecord[type];

  if (!division[levelIdx]) return null;

  return division[levelIdx] + "o";

}

const queryMaker = (len: number, context: string) => {
  if (context.split("o").length === len) {
    return ["context", "==", context] as WhereOptions;
  }
  return ["allowedContexts", "array-contains", context] as WhereOptions;
};

const getContextLevel = (context: string): number => {
  return context.split("o").filter(Boolean).length - 1;
}

const contextAllowed = (userContextLevel: number, maxContextLevel: number): boolean =>{
  return userContextLevel <= maxContextLevel;
}

export {
  companyFromContext,
  regionFromContext,
  towerFromContext,
  localFromContext,
  slaveFromContext,
  edificationFromContext,
  floorFromContext,
  queryMaker,
  getContextLevel,
  contextAllowed,
  getAllowedEntity,
};
