import { Content } from "pdfmake/interfaces";
import { ExternalSlave } from "../../interfaces/slave";
import { CurrencyFormatFunction } from "../useCurrency.hook";
import { UnitFormatFunction } from "../useUnit.hook";
import { SlaveBill } from '../../interfaces/bill';

export const getSlaveContract = (extSlave: ExternalSlave, index: number): Content => {
  const startingPage = (index + 1) % 5 === 0;
  return {
    pageBreak: startingPage || (index > 0 && index % 2 === 0) ? 'before' : undefined,
    layout: 'noBorders',
    margin: [49, startingPage || (index > 0 && index % 2 === 0) ? 39 : 0, 49, 5],
    table: {
      headerRows: 0,
      body: [
        [
          {
            text: "Cuenta Contrato: " + extSlave.contract,
            fontSize: 14,
            bold: true,
          }
        ]
      ]
    }
  }
};

export const getSlaveDescription = (extSlave: ExternalSlave): Content => ({
  layout: 'noBorders',
  margin: [49, 0, 49, 10],
  table: {
    headerRows: 0,
    body: [
      [
        {
          text: extSlave.description,
          fontSize: 12,
        }
      ]
    ]
  }
});

export const getSlaveTable = (
  slaveBill: SlaveBill,
  tax: number,
  kVaUnitCost: number,
  kwhUnitCost: number,
  kWh: UnitFormatFunction,
  kVA: UnitFormatFunction,
  Bs: CurrencyFormatFunction,
  BsPrec: CurrencyFormatFunction
): Content => ({
  margin: [49, 0, 49, 10],
  table: {
    widths: ["80%", "20%"],
    headerRows: 0,
    body: [
      [
        {
          text: "Consumo medido",
          bold: true,
          fontSize: 12,
        },
        {
          text: kWh(slaveBill.measuredConsumption),
          fontSize: 12,
          alignment: "right",
        },
      ],
      [
        {
          text: "Demanda facturada",
          bold: true,
          fontSize: 12,
        },
        {
          text: kVA(slaveBill.invoicedDemand),
          fontSize: 12,
          alignment: "right",
        },
      ],
      [
        {
          text: "Ajuste por combustible",
          bold: true,
          fontSize: 12,
        },
        {
          text: kWh(slaveBill.fuelAdjustment),
          fontSize: 12,
          alignment: "right",
        },
      ],
      [
        {
          text: "Valor unitario de demanda kVA",
          bold: true,
          fontSize: 12,
        },
        {
          text: BsPrec(kVaUnitCost),
          fontSize: 12,
          alignment: "right",
        },
      ],
      [
        {
          text: "Valor unitario de energía KWh",
          bold: true,
          fontSize: 12,
        },
        {
          text: BsPrec(kwhUnitCost),
          fontSize: 12,
          alignment: "right",
        },
      ],
      [
        {
          text: "Subtotal (sin IVA)",
          bold: true,
          fontSize: 12,
        },
        {
          text: Bs(slaveBill.total),
          fontSize: 12,
          alignment: "right",
        },
      ],
      [
        {
          text: "Total por servicios de electricidad",
          bold: true,
          fontSize: 12,
        },
        {
          text: Bs(slaveBill.total * (1 + (tax / 100))),
          fontSize: 12,
          alignment: "right",
        },
      ],
    ]
  }
});
