import './App.less';
import { Provider } from 'react-redux';

import { store } from './config/store.config';
import Routes from './routes/routes.component';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { ReactReduxFirebaseProps } from './config/firebase.config';

function App() {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...ReactReduxFirebaseProps}>
                <Router>
                    <Routes />
                </Router>
            </ReactReduxFirebaseProvider>
        </Provider>
    );
}

export default App;
