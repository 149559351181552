import React from "react";

import Spin from "antd/lib/spin";

const Loader = () => {
  return (
    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Spin size='large' />
    </div>
  );
};

export default Loader;
