import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "redux-firestore";
import { useLocation } from "react-router-dom";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
// import Menu from 'antd/lib/menu';
import Card from "antd/lib/card";
import Button from "antd/lib/button";
import Avatar from "antd/lib/avatar";
import Layout from "antd/lib/layout";
import Tooltip from "antd/lib/tooltip";
import Image from "antd/lib/image";
import Select from "antd/lib/select";
import Typography from "antd/lib/typography";

// import WifiOutlined from '@ant-design/icons/WifiOutlined';
import UserOutlined from "@ant-design/icons/UserOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
// import GatewayOutlined from '@ant-design/icons/GatewayOutlined';
import PoweroffOutlined from "@ant-design/icons/PoweroffOutlined";
import ClusterOutlined from "@ant-design/icons/ClusterOutlined";
import ThunderboltOutlined from "@ant-design/icons/ThunderboltOutlined";
import ApiOutlined from "@ant-design/icons/ApiOutlined";
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";

import Loader from "./loader.component";
import useAuth from "../hooks/useAuth.hook";
import useCredentials from "../hooks/useCredentials.hook";
import useWindowDimensions from "../hooks/useWindowDimensions.hook";
import { UserCredentials } from "../interfaces/credentials";
import { contextAllowed, getContextLevel } from "../utils/context-helper";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { FileDoneOutlined } from "@ant-design/icons";
import Permit from "../interfaces/permit";

type NavigationNodeType = "primary" | "text";

interface MainLayoutNavigationNode {
  text: string;
  path: string;
  icon?: ReactElement,
  type: NavigationNodeType;
  admin: boolean;
  superAdmin: boolean;
  maxContextLevel?: number;
  children?: MainLayoutNavigationNode[];
  showFn?: (cred: Permit | UserCredentials) => boolean;
};

const { Option } = Select;
const { Title } = Typography;
const { Content, Sider, Footer } = Layout;

const isActive = (value: string, currentPath: string): NavigationNodeType => {
  if (currentPath.includes(value)) {
    return "primary";
  }
  return "text";
};

const MainLayout = ({ children }: any) => {
  const { logout, getUID } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const credentials = useCredentials();
  const [collapsed, setCollapsed] = React.useState(false);
  const onCollapse = () => setCollapsed(!collapsed);
  const location = useLocation();
  const { pathname } = location;
  const { width } = useWindowDimensions();
  const [ isSuperAdmin, setIsSuperadmin ] = useState<boolean | null>(null);
  const [ isAdmin, setIsAdmin ] = useState<boolean | null>(null);

  useFirestoreConnect({ collection: "users", doc: getUID(), storeAs: "user" });

  const user = useSelector((state: any) => state.firestore.data.user);


  const [ userContextLevel, setUserContextLevel ] = useState<number>(Infinity);

  useEffect(() => {
    if (credentials.loaded) {
      const userCredentials = credentials.currentCredentials as UserCredentials;

      setIsSuperadmin(userCredentials.context === "1o");
      setIsAdmin(userCredentials.context.split("o").length === 3);
      setUserContextLevel(getContextLevel(userCredentials.context));
    }
}, [credentials]);

  function handleChange(value: any) {
    console.log(`selected ${value}`);
    credentials.changeCredentials(value).then((data: any) => {
      dispatch({
        type: actionTypes.CLEAR_DATA,
        preserve: { data: ["currentUser", "permits"], ordered: ["permits"] },
      });
      history.push("/");
      console.log("Ready");
      //window.location.reload()
    });
  }

  useEffect(() => {
    if (credentials.loaded) {
      const userCredentials = credentials.currentCredentials as UserCredentials;

      setIsSuperadmin(userCredentials.context === "1o");
    }
  }, [credentials]);

  const navigationNodes: MainLayoutNavigationNode[] = [
    {
      text: "Servidores",
      path: "/agents",
      icon: <ApiOutlined />,
      type: isActive("/agents", pathname),
      admin: true,
      superAdmin: false,
      showFn: (cred) => cred.canReadServers !== false,
    },
    {
      text: "Medidores",
      path: "/slaves",
      icon: <ThunderboltOutlined />,
      type: isActive("/slaves", pathname),
      admin: false,
      superAdmin: false,
    },
    {
      text: "Facturas",
      path: "/bills",
      icon: <ThunderboltOutlined />,
      type: isActive("/bills", pathname),
      admin: false,
      superAdmin: false,
      children: [
        {
          text: "Facturación",
          path: "/bills",
          type: isActive("/bills/external-slaves", pathname),
          admin: false,
          maxContextLevel: 4,
          superAdmin: false,
        },
        {
          text: "Medidores Externos",
          path: "/bills/external-slaves",
          type: isActive("/bills/external-slaves", pathname),
          admin: false,
          maxContextLevel: 4,
          superAdmin: false,
        }
      ]
    },
    {
      text: "Usuarios",
      path: "/users",
      icon: <UsergroupAddOutlined />,
      type: isActive("/users", pathname),
      admin: true,
      superAdmin: false,
    },
    {
      text: "Configuración",
      path: "/organizations",
      icon: <ClusterOutlined />,
      type: isActive("/organizations", pathname),
      admin: false,
      superAdmin: true,
    },
  ];

  const handleLinkClick = () => {
    if (width <= 1024) setCollapsed(true);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        style={
          width < 700
            ? {
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 10,
                minHeight: "100vh",
              }
            : {}
        }
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="light"
        width="288px"
      >
        <Logo>
          {collapsed ? null : (
            <img src="/logo-pollux.png" alt="Pollux" width="70%"></img>
          )}
        </Logo>

        <AvatarCard
          onClick={handleLinkClick}
          actions={[
            <Link to={`/users/${getUID()}`}>
              <Tooltip title="Editar perfil">
                <EditOutlined key="edit" />
              </Tooltip>
            </Link>,
            <Tooltip title="Cerrar sesión">
              <PoweroffOutlined onClick={logout} key="PoweroffOutlined" />
            </Tooltip>,
          ]}
        >
          {/* {user?.image ? (
            <Avatar
              style={{ margin: "1rem 0" }}
              size={112}
              src={
                <Image preview={false} src={user?.image || <UserOutlined />} />
              }
            />
          ) : (
            <Avatar
              style={{ margin: "1rem 0" }}
              size={112}
              icon={<UserOutlined />}
            />
          )} */}
          <div>
            <Title level={5} style={{ margin: 0 }}>
              {user?.name || ""} {user?.lastname || ""}
            </Title>
          </div>
        </AvatarCard>
        {/* SELECT DE CREDENCIALES */}
        {credentials?.credentialsOrdered?.length > 1 && (<SelectContainer>
          <Typography> Credenciales </Typography>
          {credentials.loaded ? (
            <Select
              defaultValue={credentials.currentCredentials.id}
              style={{ width: "150px" }}
              onChange={handleChange}
            >
              {credentials.credentialsOrdered.map((credential: any, i: number) => {
                return (
                  <Option key={'option-' + i} value={credential.id}>
                    {" "}
                    {credential.description}{" "}
                  </Option>
                );
              })}
              {/* <Option value="dayly">CREDENCIALES 1</Option>
                            <Option value="monthly">CREDENCIALES 2</Option>
                            <Option value="disabled" disabled> CREDENCIALES 3 </Option>
                            <Option value="yearly">CREDENCIALES 4</Option> */}
            </Select>
          ) : (
            <Typography> Loading... </Typography>
          )}
        </SelectContainer>)}
        <Menu mode="inline" style={{ fontSize: "1rem", paddingLeft: 44 }}>
          {
            isSuperAdmin !== null && isAdmin !== null &&
            navigationNodes
              .map((node, i) => {

                const nodeMaxContextLevel = node.maxContextLevel || Infinity;
                const superAdminGuardAllows = isSuperAdmin || !node.superAdmin;
                const adminGuardAllows = isSuperAdmin || isAdmin || !node.admin;
                const contextGuardAllows = contextAllowed(userContextLevel, nodeMaxContextLevel);
                const fnGuardAllows = node.showFn ? node.showFn(credentials.currentCredentials) : true;
                const showNode = 
                  superAdminGuardAllows && 
                  adminGuardAllows && 
                  contextGuardAllows && 
                  fnGuardAllows;
                const nodeHasChildren = node.children?.length;

                if (!showNode) return null;

                if (nodeHasChildren) {
                  const allowedChildren = node.children!.filter((child) => {
                    const maxContext = child.maxContextLevel || Infinity;
                    const superAdminFree = isSuperAdmin || !child.superAdmin;
                    const adminFree = isAdmin || !child.admin;
                    const contextFree = contextAllowed(userContextLevel, maxContext);
                    return superAdminFree && adminFree && contextFree;
                  });
                  
                  if (allowedChildren.length) {
                    return (
                      <SubMenu key={'main-navigation-node-' + i} icon={<FileDoneOutlined />} title={node.text}>
                      {
                        allowedChildren.map((child, j) => {
                          
                          return (
                            <Menu.Item onClick={handleLinkClick}
                              icon={child.icon || null} 
                              key={"main-navigation-node-" + i + "-" + j }
                            >
                              <Link to={child.path}>
                                {child.text}
                              </Link>
                            </Menu.Item>
                          )
                        })
                      }
                    </SubMenu>
                    )
                  }

                  return null;
                }

                return (
                  <Menu.Item onClick={handleLinkClick} key={"main-navigation-node-" + i} icon={node.icon}>
                    <Link to={node.path}>
                        {node.text}
                    </Link>
                  </Menu.Item>
                );

              })
          }
        </Menu>
 
        {/* 
                <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item key="2" icon={<ThunderboltOutlined />}>
                        <Link to="/agents">Agentes</Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<GatewayOutlined />}>
                        <Link to="/gateways">Gateways</Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<WifiOutlined />}>
                        <Link to="/slaves">Medidores</Link>
                    </Menu.Item>
                </Menu> */}
      </Sider>
      <StyledLayout style={{ overflowY: "auto" }}>
        {/* <Header className="header">
                    <div>

                    </div>
                    <Dropdown overlay={HeaderMenu} placement="bottomLeft">
                        <Avatar size="large" icon={<UserOutlined />} />
                    </Dropdown>
                </Header> */}
        <Content className="content-box">
          <Row className="content">
            <Col style={{ minHeight: "80vh" }} span={24}>
              <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
              span={24}
            >
              <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
                Copyright © 2021-2022. Todos los derechos reservados.
              </Footer>
            </Col>
          </Row>
        </Content>
      </StyledLayout>
    </Layout>
  );
};

export default MainLayout;

const Logo = styled(Card)`
  font-family: "Mulish", sans-serif;
  color: black;
  font-size: 16px;
  height: 56px;
  width: 200px;
  margin: 16px 44px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarCard = styled(Card)`
  width: 200px;
  heigth: 200px;
  margin: 16px 44px;
  text-align: center;

  .ant-card-actions {
    border-radius: 0 0 10px 10px;
  }

  .name {
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0 -8px 0;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 64px 44px 16px 44px;
  gap: 15px;
`;

const SelectContainer = styled(Card)`
  font-family: "Mulish", sans-serif;
  color: black;
  font-size: 16px;
  height: 80px;
  width: 200px;
  margin: 16px 44px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLayout = styled(Layout)`
  background-color: white;

  .header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    /* padding-left: 1rem 2rem; */
  }

  .content-box {
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .content {
    display: flex;
    background-color: white;
    /* height: 90%; */
    width: 90%;
    margin: 1rem;
    padding: 2rem 2rem 1rem 2rem;
  }
`;

// #f0f2f5
