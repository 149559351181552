import { lazy } from "react";

const UsersList = lazy(() => import("./list.view"));
const UserDetails = lazy(() => import("./detail.view"));

const routes = [
  {
    name: "Users list",
    path: "/users",
    component: UsersList,
    exact: true,
    public: false,
    admin: true
  },
  {
    name: "User detail",
    path: "/users/:id",
    component: UserDetails,
    exact: true,
    public: false,
    admin: false
  },
];

export default routes;
