import { ElecUnit, useUnit } from "../../../hooks/useUnit.hook";
import { MeasureRecord } from "../../../interfaces/record";
import Slave, { SlaveDisplayCoefs } from "../../../interfaces/slave";

export const useSlaveFormatting = () => {

    const { format: kVA } = useUnit(ElecUnit.kva);
    const { format: elec } = useUnit();

    const coef = (value: number, type: keyof SlaveDisplayCoefs, coefs?: SlaveDisplayCoefs, ): number => {
      if (!coefs) return value;
      if (coefs[type] === undefined) return value;
      if (!coefs[type]!.op || !coefs[type]!.value) return value;

      switch (coefs[type]!.op) {
        case "div": {
          return value / coefs[type]!.value;
        }
        case "mul": {
          return value * coefs[type]!.value;
        }
      }
    }
    
    const getTotalMonthEnergyValue = (_slave: Slave): string => {
        const originalValue = _slave.totalPositiveActiveEnergy || 0;
        const unit = _slave.unitTotalPositiveActiveEnergy;
        let finalValue = 0;
        switch(_slave.configFile) {
          case "legrand_4680.json": {
            finalValue = coef((_slave.totalPositiveActiveEnergy || 0) / 100, "totalPositiveActiveEnergy", _slave.displayCoefs);
            break;
          }
          case "legrand_14669.json": {
            finalValue = coef((_slave.totalPositiveActiveEnergy || 0), "totalPositiveActiveEnergy", _slave.displayCoefs);
            break;
          }
          case "legrand_412053.json": {
            finalValue = coef((_slave.totalPositiveActiveEnergy || 0) / 100, "totalPositiveActiveEnergy", _slave.displayCoefs);
            break;
          }
          default: {
            finalValue = coef(originalValue, "totalPositiveActiveEnergy", _slave.displayCoefs);
            break;
          }
        }

        return elec(finalValue, unit || "W");
      }
    
      const getPeakMaximumDemandValue = (_slave: Slave): string => {
    
        const originalValue = _slave.peakMaximunDemand || 0;
        let finalValue = 0;
        switch(_slave.configFile) {
          case "legrand_4686.json": {
            finalValue = coef((originalValue) / 100, "peakMaximunDemand", _slave.displayCoefs);
            return kVA(finalValue);
          }
          case "legrand_4680.json": {
            finalValue = coef((originalValue) / 100, "peakMaximunDemand", _slave.displayCoefs);
            return kVA(finalValue);
          }
          case "legrand_14669.json": {
            finalValue = coef((originalValue) / 100, "peakMaximunDemand", _slave.displayCoefs);
            return kVA(finalValue);
          }
          default: {
            finalValue = coef(originalValue, "peakMaximunDemand", _slave.displayCoefs);
          }
        }

        return kVA(finalValue);
      }

      const getIndirectPositiveActiveEnergyRecord = (record: MeasureRecord, slaveCoefs?: SlaveDisplayCoefs): string => {
        const originalValue = record.energy_result;
        let finalValue = 0;
        switch(record.slave_model) {
          case "04686": {
            finalValue = coef((originalValue) * 100, "kwh", slaveCoefs);
            break;
          }
          case "14669": {
            finalValue = coef((originalValue) * 100, "kwh", slaveCoefs);
            break;
          }
          default: {
            finalValue = coef(originalValue, "kwh", slaveCoefs);
            break;
          }
        }

        return elec(finalValue);
      }
    
      const getPeakMaximumDemandRecordValue = (record: MeasureRecord, slaveCoefs?: SlaveDisplayCoefs): string => {
    
        const originalValue = record.peak_result;
        let finalValue = 0;
        switch(record.slave_model) {
          case "04686": {
            finalValue = coef((originalValue) / 100, "kva", slaveCoefs);
            break;
          }
          case "14669": {
            finalValue = coef((originalValue) / 100, "kva", slaveCoefs);
            break;
          }
          default: {
            finalValue = coef(originalValue, "kva", slaveCoefs);
            break;
          }
        }

        return elec(finalValue);
      }
    
      // const getPeakMaximumDemandGraphValue = (originalValue: number, slaveModel: string, slaveCoefs: SlaveDisplayCoefs): number => {
    
      //   let finalValue = 0;
      //   switch(slaveModel) {
      //     case "04686": {
      //       finalValue = coef((originalValue) / 100, "kva", slaveCoefs);
      //       break;
      //     }
      //     case "14669": {
      //       finalValue = coef((originalValue) / 100, "kva", slaveCoefs);
      //       break;
      //     }
      //     default: {
      //       finalValue = coef(originalValue, "kva", slaveCoefs);
      //       break;
      //     }
      //   }

      //   return finalValue;
      // }

      // const getIndirectPositiveActiveEnergyGraphValue = (originalValue: number, slaveModel: string, slaveCoefs: SlaveDisplayCoefs): number => {
      //   let finalValue = 0;
      //   switch(slaveModel) {
      //     case "04686": {
      //       finalValue = coef((originalValue) * 10, "kwh", slaveCoefs);
      //       break;
      //     }
      //     case "14669": {
      //       finalValue = coef((originalValue) * 100, "kwh", slaveCoefs);
      //       break;
      //     }
      //     default: {
      //       finalValue = coef(originalValue, "kwh", slaveCoefs);
      //       break;
      //     }
      //   }

      //   return finalValue;
      // }
      
      const getLiveDataElement = (
        _slave: Slave, 
        value: number, 
        formatterFn: (a: number) => string,
        name?: string,
        coefKey?: keyof SlaveDisplayCoefs,
      ): string => {
        switch(_slave.configFile) {
          case "legrand_4680.json": {
            return formatterFn(coefKey ? coef(value, coefKey, _slave.displayCoefs) : value);
          }
          case "legrand_14669.json": {
            if (name === "Factor de Potencia" || name === "Frecuencia") {
              const fixedValue = (value) / 10;
              return formatterFn(coefKey ? coef(fixedValue, coefKey, _slave.displayCoefs) : fixedValue)
            }
            const fixedValue = (value) / 100;
            return formatterFn(coefKey ? coef(fixedValue, coefKey, _slave.displayCoefs) : fixedValue)
          }
          default: {
            return formatterFn(coefKey ? coef(value, coefKey, _slave.displayCoefs) : value)
          }
        }
      }

      const liveDataToDisplayCoefKey = (name: string): keyof SlaveDisplayCoefs | undefined => {
        const obj 
          : Record<string, keyof SlaveDisplayCoefs>
          = {
            "Phase 1: phase voltage": "tensionL1N",
            "Phase 2: phase voltage": "tensionL2N",
            "Phase 3: phase voltage": "tensionL3N",
            "Chained voltage: L1-L2": "tensionL1L2",
            "Chained voltage: L2-L3": "tensionL2L3",
            "Chained voltage: L3-L1": "tensionL3L1",
            "3-phase: active power": "activePower",
            "3-phase: reactive power": "reactivePower",
            "3-phase: apparent power": "apparentPower",
            "Power factor": "powerFactor",
            "Phase 1: current": "currentL1",
            "Phase 2: current": "currentL2",
            "Phase 3: current": "currentL3",
            "Total positive active energy": "totalPositiveActiveEnergy",
            "Frequency": "frequency",
          };

        return obj[name];
      };

    return {
        getTotalMonthEnergyValue,
        getPeakMaximumDemandValue,
        getPeakMaximumDemandRecordValue,
        getLiveDataElement,
        getIndirectPositiveActiveEnergyRecord,
        liveDataToDisplayCoefKey,
        coef,
        // getPeakMaximumDemandGraphValue,
        // getIndirectPositiveActiveEnergyGraphValue,
    }
}