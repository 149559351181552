import { lazy } from "react";

const List = lazy(() => import("./list.view"));
const Detail = lazy(() => import("./detail.view"));
const Reports = lazy(() => import("./reports/list.view"));

const routes = [
  {
    name: "Slaves list",
    path: "/slaves",
    component: List,
    exact: true,
    public: false,
  },
  // {
  // name: 'Slaves list',
  // path: '/',
  // redirect: '/dashboard',
  // component: List,
  // exact: true,
  // public: false,
  // },
  {
    name: "Slaves detail",
    path: "/slaves/:id",
    component: Detail,
    exact: true,
    public: false,
  },
  {
    name: "Slaves Reports",
    path: "/slaves/:id/reports",
    component: Reports,
    exact: true,
    public: false,
  },
];

export default routes;
