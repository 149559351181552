function serviceWorkerReducer(state = {} as any, action: any) {
  switch (action.type) {
    case "SW_INIT":
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
        serviceWorkerRegistration: action.payload,
      };
    case "SW_UPDATE":
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
}

export default serviceWorkerReducer;
