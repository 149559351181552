import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import AuthLoader from "../components/auth-loader.component";
import LoaderOverlay from "../components/loder-overlay.component";
// import Modules from "../modules";

import ProtectedRouter from "./protected-router.component";
import ViewsRoutes from "../views";

const routeIsPublic = (route: any) => route.public;
const routeIsPrivate = (route: any) => !route.public && !route.admin && !route.superAdmin;
const routeIsForAdmin = (route: any) => route.admin && !route.public;
const routeIsForSuperAdmin = (route: any) => route.superAdmin && !route.public && !route.admin;

// const AppLoader = React.lazy(() => import("../components/app-loader.component"));
const publicRoutes = ViewsRoutes.filter(routeIsPublic);
const privateRoutes = ViewsRoutes.filter(routeIsPrivate);
const adminRoutes = ViewsRoutes.filter(routeIsForAdmin);
const superAdminRoutes = ViewsRoutes.filter(routeIsForSuperAdmin);

const Routes = () => (
  <AuthLoader>
    <Suspense fallback={<LoaderOverlay />}>
      <Switch>
        {publicRoutes.map((route) => (
          <Route key={route.name} {...route} />
        ))}
        {/* <Route component={AppLoader} path='/app' exact /> */}
        <ProtectedRouter 
          privateRoutes={privateRoutes} 
          adminRoutes={adminRoutes} 
          superAdminRoutes={superAdminRoutes}
        />
      </Switch>
    </Suspense>
  </AuthLoader>
);

export default Routes;
