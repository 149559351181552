import { FirestoreTimestamp, RichTimestamp } from "../interfaces/general";

export const tsToDate = (ts: RichTimestamp): Date => {
    try {
        if ((ts as FirestoreTimestamp).toDate()) return (ts as FirestoreTimestamp).toDate();
    } catch (error) {
        return ts as Date;
    }
    return ts as Date;
  
}