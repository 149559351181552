import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/functions";
import "firebase/storage";
import "firebase/auth";

import { createFirestoreInstance } from "redux-firestore";
import { store } from "./store.config";

const config = {
  apiKey: "AIzaSyDD6_190uQzCJ-Zlz3myztk2SOPuy4oPo4",
  authDomain: "ukko-pollux.firebaseapp.com",
  projectId: "ukko-pollux",
  storageBucket: "ukko-pollux.appspot.com",
  messagingSenderId: "298335440805",
  appId: "1:298335440805:web:ad2b8ae5657ccf2e72224c",
  measurementId: "G-4S5TLSDVDP",
};

firebase.initializeApp(config);
firebase.firestore();

const vapidKey = "BPQkFxQNRaSHJqv_0PiRBksVR5pSZva1p_KDJVs1j7koAoeRTr2wpi_4_HzePhYF0G0-3xtAKO3b4b_KG8K8qUo";
let messaging: firebase.messaging.Messaging;
let serviceWorkerRegistration: ServiceWorkerRegistration;
const functions = firebase.functions();
// if (firebase.messaging.isSupported()) {
//   messaging = firebase.messaging();
//   if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//       .register("../../firebase-messaging-sw.js")
//       .then((registration) => {
//         serviceWorkerRegistration = registration;
//         messaging.onMessage((payload: any) => {
//           const title = payload.notification.title;
//           const options = {
//             body: payload.notification.body,
//             icon: payload.notification.icon,
//           };
//           serviceWorkerRegistration.showNotification(title, options);
//         });
//       })
//       .catch((error) => {
//         console.error("Error setting up notification service worker", error);
//       });
//   }
// }

export const reactReduxFirebaseConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  preserveOnDelete: true,
  mergeOrdered: false,
};

export const ReactReduxFirebaseProps: any = {
  firebase,
  config: reactReduxFirebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export { messaging, vapidKey, serviceWorkerRegistration, functions };
export default firebase;
