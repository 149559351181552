export enum CurrencyCode {
    usd = "usd",
    ves = "ves",
};

const currencySignDict
    : Record<CurrencyCode, string>
    = {
        [CurrencyCode.usd]: "$",
        [CurrencyCode.ves]: "Bs."
    };

export type AmountSeparator = "comma" | "dot";

export interface CurrencyFormatOptions {
    showSign?: boolean;
    kSeparator?: AmountSeparator;
    maxFractionDigits?: number;
}

const defaultFormatOptions
    : Required<CurrencyFormatOptions>
    = {
        showSign: true,
        kSeparator: "dot",
        maxFractionDigits: 2,
    };

export type CurrencyFormatFunction = (amount: number) => string;

export const useCurrency = (code: CurrencyCode = CurrencyCode.ves, options: CurrencyFormatOptions = {}) => {

    options = { ...defaultFormatOptions, ...options };
    const {
        showSign,
        kSeparator,
        maxFractionDigits,
    } = options;

    const config = new Intl.NumberFormat(
        kSeparator === "comma" ? "en-US" : "de-DE", 
        { maximumFractionDigits: maxFractionDigits, minimumIntegerDigits: 1, minimumFractionDigits: 2 }
    );

    const format: CurrencyFormatFunction = (amount: number) => {
        try {
            let result: string = config.format(amount);
    
            if (showSign) result = currencySignDict[code] + " " + result;
    
            return result;
        } catch (error) {
            console.error("Error formatting value:", amount, "\nDefault string was returned. Original error:\n", error);
            return "NaN";
        }
    }

    return { format };
}