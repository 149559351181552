import Moment from 'moment';

const addExtraZero = (dd: number) => {
    if (dd < 10) {
        return `0${dd}`;
    }
    return dd;
};

const format = (date: Date) => {
    return `${addExtraZero(date.getDate())}/${addExtraZero(
        date.getMonth() + 1
    )}/${date.getFullYear()} - ${addExtraZero(date.getHours())}:${addExtraZero(
        date.getMinutes()
    )}:${addExtraZero(date.getSeconds())}`;
};

export const formatDate = (date: Date) => date.toLocaleDateString("fr-FR");
export const formatDateExportable = (date: Date) => Moment(date).format('DD.MM.YYYY')

export default format;
