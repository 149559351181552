import { AllowedContexts, AllowedContextsNames, ContextsNames } from "./context";
import { RichTimestamp } from "./general";
import { ExternalSlave } from "./slave";

export type DownloadBillSettingsGroupType = "slave" | "local";
export type DownloadBillSettingsBillType = "general" | "group-tables" | "compact";

export enum BillType {
  SLAVE = "SLAVES",
  LOCAL = "LOCALS",
}

export enum BillStatus {
  PENDING = "PENDING",
  PAYED = "PAYED",
  DELETED = "DELETED",
}

export interface SlaveBill {
  fuelAdjustment: number;
  invoicedDemand: number;
  measuredConsumption: number;
  total: number;
}

export interface BillRealFormValues {
  tax: number;
  fuelUnitCost: number;
  kVaUnitCost: number;
  kwhUnitCost: number;
  slavesBills: SlaveBill[];
  billType: BillType[];
}

export interface DownloadBillSettings {
  groupType: DownloadBillSettingsGroupType;
  billTypesMap: { [ key in DownloadBillSettingsBillType ]: boolean };
}

export interface BillCompanyData {
  avatarUrl: string;
  name: string;
  rif: string;
  address: string;
  email: string;
  phone: string;
}

export interface BillRepresentantData {
  name: string;
  id: string;
}

export interface BillCreate {
  allowedContexts: AllowedContexts;
  allowedContextsNames: AllowedContextsNames;
  beginDate: RichTimestamp;
  beginDateString: string;
  billType: BillType[];
  company: string;
  context: string;
  contextsNames: ContextsNames;
  edification: string;
  endDate: RichTimestamp;
  endDateString: string;
  externalSlaves: ExternalSlave[];
  fuelAdjustment: number;
  fuelUnitCost: number;
  invoicedDemand: number;
  isComplete: boolean;
  kVaUnitCost: number;
  kwhUnitCost: number;
  measuredConsumption: number;
  physicalRegion: string;
  realFormValues: BillRealFormValues;
  status: BillStatus;
  tax: number;
  taxCost: number;
  total: number;
  totalCostFuelAdjustment: number;
  totalCostInvoicedDemand: number;
  totalCostMeasuredConsumption: number;
  totalCostNoTax: number;
  totalCostTax: number;
  totalDays: number;
  tower: string;
  externalSlavesIds: string[];
}

export interface CommonAreasBillItems {
  commonAreasEnergy: number;
  commonAreasEnergyCost: number;
  commonAreasPeakEnergy: number;
  commonAreasPeakEnergyCost: number;
}

export interface Bill extends BillCreate, CommonAreasBillItems {
  id: string;
  companyData: BillCompanyData;
  representantData: BillRepresentantData;
  slavesTotalCost: number;
  slavesTotalCostTax: number;
}

export interface BillSlaveMeasure {
  fuelAdjustment: number;
  fuelAdjustmentSubtotal: number;
  name: string;
  peakDemand: number;
  peakDemandPercentage: number;
  peakDemandSubtotal: number;
  slaveId: string;
  slaveLocalId: string;
  subtotal: number;
  totalCost: number;
  totalCostTax: number;
  totalEnergy: number;
  totalEnergyPercentage: number;
  totalKWh: number;
}