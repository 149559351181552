import { lazy } from 'react';

const Floor = lazy(() => import('./floor.view'));
const Company = lazy(() => import('./company.view'));
const Companies = lazy(() => import('./companies.view'));
const Edification = lazy(() => import('./edification.view'));

const routes = [
    {
        name: 'Companies list',
        path: '/organizations',
        component: Companies,
        exact: true,
        public: false,
        superAdmin: true,
    },
    {
        name: 'Companies list',
        path: '/organizations/:companyId',
        component: Company,
        exact: true,
        public: false,
        superAdmin: true,
    },
    {
        name: 'Edification detail',
        path: '/organizations/:companyId/:regionId/:edificationId',
        component: Edification,
        exact: true,
        public: false,
        superAdmin: true,
    },
    {
        name: 'Floor detail',
        path: '/organizations/:companyId/:regionId/:edificationId/:towerId/:floorId',
        component: Floor,
        exact: true,
        public: false,
        superAdmin: true,
    },
];

export default routes;
