import React from "react";
import Spin from "antd/lib/spin";
import styled from "styled-components";

const CenteredScreen = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
`;

function LoaderOverlay() {
  return (
    <CenteredScreen>
      <Spin size='large' />
    </CenteredScreen>
  );
}

export default LoaderOverlay;
