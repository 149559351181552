import { lazy } from "react";
// import Entities from './entities';
// import Organizations from './organizations';
import Slaves from "./slaves";
import Bills from "./bills";
import Entities from "./entities2";
import Users from "./users";
import ExternalSlavesBillsView from "./bills/external-slaves-bills";
import Permit from "../interfaces/permit";
import { UserCredentials } from "../interfaces/credentials";
// PUBLIC ROUTES
const LogIn = lazy(() => import("./log-in.view"));
const PasswordRecovery = lazy(() => import("./password-recovery.view"));
const WelcomeComponent = lazy(() => import("./welcome.view"));
// PRIVATE ROUTES
const Dashboard = lazy(() => import("./dashboard.view"));
const Agents = lazy(() => import("./agents.view"));
const Agent = lazy(() => import("./agent.view"));
const Gateways = lazy(() => import("./gateways.view"));
const Gateway = lazy(() => import("./gateway.view"));
const RemoteReadings = lazy(() => import("./remote-reading.view"));
const BillsList = lazy(() => import("./bills/list.view"));

const routes: Array<any> = [
  {
    name: "Log in",
    path: "/log-in",
    component: LogIn,
    exact: true,
    public: true,
  },
  {
    name: "Password Recovery",
    path: "/password-recovery",
    component: PasswordRecovery,
    exact: true,
    public: true,
  },
  {
    name: "Welcome",
    path: "/welcome/:token",
    component: WelcomeComponent,
    exact: true,
    public: true,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    exact: true,
    public: false,
  },
  {
    name: "Agents",
    path: "/agents",
    component: Agents,
    exact: true,
    public: false,
    showFn: (cred: Permit | UserCredentials) => cred.canReadServers !== false,
  },
  {
    name: "Agent",
    path: "/agents/:id",
    component: Agent,
    exact: true,
    public: false,
    showFn: (cred: Permit | UserCredentials) => cred.canReadServers !== false,
  },
  {
    name: "Gateways",
    path: "/gateways",
    component: Gateways,
    exact: true,
    public: false,
  },
  {
    name: "Gateway",
    path: "/gateways/:id",
    component: Gateway,
    exact: true,
    public: false,
  },
  {
    name: "Records",
    path: "/records",
    component: RemoteReadings,
    exact: true,
    public: false,
  },
  {
    name: "Bills",
    path: "/bills",
    component: BillsList,
    exact: true,
    public: false,
    admin: false,
  },
  {
    name: "External slaves bills",
    path: "/bills/external-slaves",
    component: ExternalSlavesBillsView,
    exact: true,
    public: false,
    admin: false,
    maxContextLevel: 4,
  },
  // ...Organizations,
  ...Entities,
  ...Slaves,
  ...Users,
  //...Bills
];

export default routes;
