import { Col, Row, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import useCredentials from '../../../hooks/useCredentials.hook';
import { ExternalSlave } from '../../../interfaces/slave';
import { useEffect } from 'react';
import { UserCredentials } from '../../../interfaces/credentials';
import { useFirestore } from 'react-redux-firebase';
import Loader from '../../../components/loader.component';
import { Bill, BillStatus } from '../../../interfaces/bill';
import styled from 'styled-components';
import ExternalSlaveModal from './external-slave.modal';
import ExternalSlaveCard from './external-slave-card';

interface ExternalSlaveItemsInput {
  items: ExternalSlave[];
  onSee: (extSlave: ExternalSlave) => void;
}
const ExternalSlavesItems = ({ items, onSee }: ExternalSlaveItemsInput) => {
  return (
    <>
      {items.map((extSlave) => (
        <Col key={extSlave.id} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} >
          <ExternalSlaveCard data={extSlave} onSee={onSee} />
        </Col>
      ))}
    </>
  );
}

// { [id]: bills }
// { [id]: null } <-- being fetched
// { [id]: undefined } <-- not fetched yet
type BillsRecord = Record<string, Bill[] | null>;

const ExternalSlavesBillsView = () => {
  const credentials = useCredentials();
  const db = useFirestore();

  const [ extSlaves, setExtSlaves ] = useState<ExternalSlave[]>();
  const [ selectedExtSlave, setSelectedSlave ] = useState<ExternalSlave | null>(null);
  const [ billsRecord, setBillsRecord ] = useState<BillsRecord>({});
  const [ currentCredentials, setCurrentCredentials ] = useState<UserCredentials>();

  const updateBillsRecord = (extSlaveId: string, bills: Bill[] | null | undefined) => {
    if (bills === undefined) {
      setBillsRecord((prev) => {
        delete prev[extSlaveId];
        return prev;
      });

      return;
    }

    setBillsRecord((prev) => ({ ...prev, [extSlaveId]: bills! }));
  }

  const fetchBills = async (extSlaveId: string): Promise<Bill[]> => {

    if (billsRecord[extSlaveId]) return Promise.resolve(billsRecord[extSlaveId]!);
    if (billsRecord[extSlaveId] === null) Promise.resolve(null);

    updateBillsRecord(extSlaveId, null);

    try {
      const snap = await db
        .collection("bills")
        .where("externalSlavesIds", "array-contains", extSlaveId)
        .where("status", "!=", BillStatus.DELETED)
        .get();

      const bills: Bill[] = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Bill));
  
      return Promise.resolve(bills);
    } catch (error) {
      updateBillsRecord(extSlaveId, undefined);
      return Promise.reject(error);
    }
  }

  useEffect(() => {
    if (!selectedExtSlave) return;

    fetchBills(selectedExtSlave.id!)
      .then((bills) => {
        if (!bills) return;
        updateBillsRecord(selectedExtSlave.id!, bills);
      })
      .catch(console.log);
      
  }, [selectedExtSlave]);

  const fetchExternalSlaves = async (context: string): Promise<ExternalSlave[]> => {

    try {
      const snap = await db
        .collection("externalSlaves")
        .where("allowedContexts", "array-contains", context)
        .where("isDeleted", "==", false)
        .get();

      const extSlaves: ExternalSlave[] = snap.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }) as ExternalSlave);

      return Promise.resolve(extSlaves);
    } catch (error) {
      console.log("Error getting external slaves", error);
      return Promise.reject(error);
    }
  }

  useEffect(() => {
    if (credentials.loaded) {
      const userCredentials: UserCredentials = credentials.currentCredentials;

      if (userCredentials.context !== currentCredentials?.context) {
        setCurrentCredentials(userCredentials);
        fetchExternalSlaves(userCredentials.context)
          .then(setExtSlaves)
          .catch(console.log);
      }

    }
  }, [credentials]);

  if (!extSlaves) return <Loader />;
    
  return (
    <div>
        <Title>Medidores externos</Title>

        <Row gutter={[16, 16]}>
            <ExternalSlavesItems items={extSlaves} onSee={setSelectedSlave} />
        </Row>

        <ExternalSlaveModal 
          visible={!!selectedExtSlave} 
          bills={billsRecord[selectedExtSlave?.id || ""] || null}
          extSlave={selectedExtSlave} 
          loading={billsRecord[selectedExtSlave?.id || ""] === null}
          onCancel={() => setSelectedSlave(null)}
        />
    </div>
  );
}

export default ExternalSlavesBillsView;

const TitleColumn = styled(Col)`
  display: flex;
  font-size: 18px;
  justify-content: center;
  font-weight: 600;
  font-family: "Mulish";
`;

const Coltitle = styled(Typography)`
  font-weight: 600;
  line-height: 18px;
  font-family: "Mulish";
  font-style: normal;
  letter-spacing: 0.2px;
  color: #373f41;
`;

const Label = styled(Typography)`
  margin: 5px;
  line-height: 18px;
  font-family: "Mulish";
  letter-spacing: 0.04em;
  color: #c4c4c4;
`;