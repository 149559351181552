import React, { PropsWithChildren } from 'react';
import LoaderOverlay from './loder-overlay.component';
import useAuth from '../hooks/useAuth.hook';

const AuthLoader = ({ children }: PropsWithChildren<any>) => {
    const { isAuthLoaded } = useAuth();

    if (isAuthLoaded()) {
        return children;
    }
    return <LoaderOverlay />;
};

export default AuthLoader;
