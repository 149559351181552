import React from "react";
import moment from "moment";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import styled from "styled-components";
import { Typography } from "antd";
import { CurrencyCode, useCurrency } from "../../hooks/useCurrency.hook";
import { ElecUnit, useUnit } from "../../hooks/useUnit.hook";
import { tsToDate } from '../../utils/db.util';
import { formatDate } from '../../utils/date-formatter';

const BillDetail = (props: any) => {
  const bill = props.bill as any;
  const multiply = (value1: number, value2: number) => {
    return value1 * value2;
  };

  const { format: BsPrec } = useCurrency(CurrencyCode.ves, { maxFractionDigits: 8 });
  const { format: Bs } = useCurrency();
  const { format: kWh } = useUnit(ElecUnit.kwh);
  const { format: kVA } = useUnit(ElecUnit.kva);

  return (
    <Row gutter={[5, 5]}>
      <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
        <Card key={1} style={{ minHeight: 356 }}>
          <TitleContainer>
            <Title>
              {" "}
              {bill.contextsNames ? bill.contextsNames["company"] : "N/E"}{" "}
            </Title>
          </TitleContainer>
          <NormalCardRow>
            <Label> Región Física </Label>
            <Content>
              {" "}
              {bill.contextsNames
                ? bill.contextsNames["physicalRegion"]
                : "N/E"}{" "}
            </Content>
          </NormalCardRow>
          <NormalCardRow>
            <Label> Edificación </Label>
            <Content>
              {" "}
              {bill.contextsNames
                ? bill.contextsNames["edification"]
                : "N/E"}{" "}
            </Content>
          </NormalCardRow>
          <FlexCardRow>
            <FlexCardColumn style={{ flex: 0.5 }}>
              <Label> Edificación </Label>
              <Content>
                {" "}
                {bill.contextsNames ? bill.contextsNames["tower"] : "N/E"}{" "}
              </Content>
            </FlexCardColumn>
            {/* <FlexCardColumn style={{ flex: 0.5 }}>
              <Label> Piso </Label>
              <Content>
                {" "}
                {bill.contextsNames ? bill.contextsNames["floor"] : "N/E"}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
          <FlexCardRow>
            <FlexCardColumn style={{ flex: 0.5 }}>
              <Label> Local </Label>
              <Content>
                {" "}
                {bill.contextsNames ? bill.contextsNames["local"] : "N/E"}{" "}
              </Content>
            </FlexCardColumn>
            <FlexCardColumn style={{ flex: 0.5 }}>
              <Label> Medidor </Label>
              <Content>
                {" "}
                {bill.contextsNames ? bill.contextsNames["slave"] : "N/E"}{" "}
              </Content>
            </FlexCardColumn> */}
          </FlexCardRow>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={14} xl={14} xxl={14}>
        <Card key={2} style={{ minHeight: 356 }}>
          <TitleContainer>
            <Title> CARGOS </Title>
          </TitleContainer>
          <FlexCardRow>
            <FlexCardColumn
              style={{ flex: 0.35, justifyContent: "space-between" }}
            >
              <Label> Facturación por consumo </Label>
              <Content>
                {bill["measuredConsumption"]
                  ? kWh(bill["measuredConsumption"])
                  : "N/E"}
              </Content>
            </FlexCardColumn>
            <FlexCardColumn
              style={{ flex: 0.3, justifyContent: "space-between" }}
            >
              <Label> Valor unitario</Label>
              <Content>
                {" "}
                {(bill["kwhUnitCost"] ? BsPrec(bill["kwhUnitCost"]) : "N/A")}{" "}
              </Content>
            </FlexCardColumn>
            <FlexCardColumn
              style={{ flex: 0.35, justifyContent: "space-between" }}
            >
              <Label> Valor total </Label>
              <Content>
                {" "}
                {" "}
                {bill["totalCostMeasuredConsumption"]
                  ? Bs(bill["totalCostMeasuredConsumption"])
                  : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
          <FlexCardRow>
            <FlexCardColumn
              style={{ flex: 0.35, justifyContent: "space-between" }}
            >
              <Label> Cargo por combustible </Label>
              <Content>
                {" "}
                {bill["fuelAdjustment"] || bill["fuelAdjustment"] === 0
                  ? kWh(bill["fuelAdjustment"])
                  : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
            <FlexCardColumn style={{ flex: 0.3, justifyContent: "flex-end" }}>
              <Content>
                {" "}
                {bill["fuelUnitCost"] || bill["fuelUnitCost"] === 0 ? Bs(bill["fuelUnitCost"]) : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
            <FlexCardColumn style={{ flex: 0.35, justifyContent: "flex-end" }}>
              <Content>
                {" "}
                {" "}
                {bill["totalCostFuelAdjustment"] || bill["totalCostFuelAdjustment"] === 0
                  ? Bs(bill["totalCostFuelAdjustment"])
                  : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
          <FlexCardRow>
            <FlexCardColumn
              style={{ flex: 0.35, justifyContent: "space-between" }}
            >
              <Label> Facturación por demanda </Label>
              <Content>
                {" "}
                {bill["invoicedDemand"]
                  ? kVA(bill["invoicedDemand"])
                  : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
            <FlexCardColumn style={{ flex: 0.3, justifyContent: "flex-end" }}>
              <Content>
                {" "}
                {bill["kVaUnitCost"] ? BsPrec(bill["kVaUnitCost"]) : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
            <FlexCardColumn style={{ flex: 0.35, justifyContent: "flex-end" }}>
              <Content>
                {" "}
                {" "}
                {bill["totalCostInvoicedDemand"]
                  ? Bs(bill["totalCostInvoicedDemand"])
                  : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
          <FlexCardRow>
            <FlexCardColumn
              style={{ flex: 0.65, justifyContent: "space-between" }}
            >
              <Title> IMPUESTOS </Title>
              <Label> Impuesto venta </Label>
              <Content> {bill["tax"] || bill["tax"] === 0 ? bill["tax"] + "%" : "N/A"} </Content>
            </FlexCardColumn>
            <FlexCardColumn style={{ flex: 0.3, justifyContent: "flex-end" }}>
              <Content>
                {" "}
                {" "}
                {bill["taxCost"] || bill["taxCost"] === 0
                  ? Bs(bill["taxCost"])
                  : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
          <FlexCardRow
            style={{
              padding: 10,
              backgroundColor: "#EFF6DB",
              borderRadius: "10px",
            }}
          >
            <FlexCardColumn
              style={{ flex: 0.65, justifyContent: "space-between" }}
            >
              <Title> TOTAL </Title>
            </FlexCardColumn>
            <FlexCardColumn style={{ flex: 0.3, justifyContent: "flex-end" }}>
              <Content>
                {" "}
                {" "}
                {bill["totalCostTax"]
                  ? Bs(bill["totalCostTax"])
                  : "N/A"}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
        <Card key={3} style={{ minHeight: 356 }}>
          <TitleContainer>
            <Title> PERIODO </Title>
          </TitleContainer>
          <FlexCardRow>
            <FlexCardColumn>
              <Label> Desde </Label>
              <Content>
                {" "}
                {formatDate(tsToDate(bill.beginDate))}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
          <FlexCardRow>
            <FlexCardColumn>
              <Label> Hasta </Label>
              <Content>
                {" "}
                {formatDate(tsToDate(bill.endDate))}{" "}
              </Content>
            </FlexCardColumn>
          </FlexCardRow>
          <FlexCardRow>
            <FlexCardColumn>
              <Title> ESTADO </Title>
              <Title
                style={{
                  color:
                    bill.status && bill.status == "PENDING"
                      ? "#E92828"
                      : "#35B421",
                }}
              >
                {" "}
                {bill.status
                  ? bill.status == "PENDING"
                    ? "Pendiente"
                    : "Pagado"
                  : "N/A"}{" "}
              </Title>
            </FlexCardColumn>
          </FlexCardRow>
        </Card>
      </Col>
    </Row>
  );
};

export default BillDetail;

const TitleContainer = styled.div`
  padding: 0px 0px 10px 0px;
`;

const NormalCardRow = styled.div`
  padding: 10px 0px 10px 0px;
`;

const FlexCardRow = styled.div`
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-wrap: wrap;
`;

const FlexCardColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled(Typography)`
  margin-bottom: 0px;
  line-height: 18px;
  font-family: "Mulish";
  letter-spacing: 0.04em;
  color: #c4c4c4;
`;

const Content = styled(Typography)`
  margin-bottom: 0px;
  font-weight: 600;
  line-height: 18px;
  font-family: "Mulish";
  letter-spacing: 0.04em;
  color: #000000;
`;

const Title = styled(Typography)`
  margin-bottom: 0px;
  font-weight: 800;
  line-height: 18px;
  font-family: "Mulish";
  letter-spacing: 0.04em;
  color: #4f4f4f;
`;
